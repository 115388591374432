import Editor from "@monaco-editor/react";
import { useState } from "react";
import AssessmentButtons from "./AssessmentButtons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { PropagateLoader } from "react-spinners";
const core = require("../../../lib/core");

export default function CodingSection({
  interviewId,
  endTest,
  codeQsn,
  setAnswers,
  navigation,
  setPassedCases,
  selectedAnswers,
  descriptiveQsnLength,
  testCases,
  qsnData,
}) {
  const [value, setValue] = useState();
  const [qno, setQno] = useState(0);
  const [language, setLanguage] = useState(
    new Array(codeQsn?.length).fill("python"),
  );

  const [inputValues, setInputValues] = useState(
    codeQsn?.map((val, index) => val?.data?.skeletons[language[index]]),
  );

  useEffect(() => {
    setValue(inputValues[qno]);
  }, [inputValues, qno]);

  console.log(value);

  const [processing, setProcessing] = useState(null);
  const [testOutput, setTestOutput] = useState(testCases?.[qno] || {});

  useEffect(() => {
    if (codeQsn?.length > 0) {
      setLanguage(new Array(codeQsn?.length).fill("python"));
    }
  }, [codeQsn]);

  useEffect(() => {
    if (codeQsn?.length > 0) {
      if (selectedAnswers[qno]) {
        setInputValues(
          selectedAnswers ||
            codeQsn?.map((val, index) => val?.data?.skeletons?.python),
        );
      } else {
        setInputValues(
          codeQsn?.map((val, index) => val?.data?.skeletons?.python),
        );
      }
    }
  }, [codeQsn]);

  function handleChangeInput(val) {
    setInputValues((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[qno] = val;
      return newInputValues;
    });
    setAnswers(val, qno);
    setValue(val);
  }

  function handleChangeLanguage(val) {
    setLanguage((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[qno] = val;
      return newInputValues;
    });
    setInputValues((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[qno] = codeQsn[qno]?.data?.skeletons[
        val == "cpp" ? "c++" : val
      ]
        ?.split("&lt;")
        ?.join("<")
        ?.split("&gt;")
        ?.join(">");
      return newInputValues;
    });
  }

  const [reload, setReload] = useState(0);

  const runCode = async (api, qId, test) => {
    setProcessing(true);
    let json = await core.API(core.API_METHODS.POST, api, 1, {
      code: value,
      // call: codeQsn[qno]?.data?.skeletons[
      //   JSON.parse(language[qno])?.name + "_call"
      // ],
      language: language[qno],
      coding_index: qno,
      // challenge: qId,
      interviewId: interviewId,
    });
    if (json?.data) {
      setProcessing(false);
      console.log(json?.data);
      if (test) {
        setPassedCases(json?.data, qno);
        setTestOutput(json.data);
        setReload(reload + 1);
      } else {
        setTestOutput(json.data);
      }
    } else {
      toast.error(json?.error?.message);
      setProcessing(null);
      console.log(json?.error);
    }
  };

  const formateedText = (textfild) => {
    return textfild.replace(/\n/g, "<br />");
  };

  return (
    <section className="col-xs-10 " id="codingSection">
      <div className="col-xs-12 col-md-3 left-section">
        <div className="col-xs-12 no-padding question-sec">
          <h4>Question {qno + 1}</h4>
          <h3>{codeQsn[qno]?.problem}</h3>
          <div className="section-data col-xs-12 no-padding">
            {codeQsn[qno]?.data?.function_description ??
              codeQsn[qno]?.data["function description"]}
          </div>
          <div className="section-data col-xs-12 no-padding">
            <pre>
              {codeQsn[qno]?.data?.example ?? codeQsn[qno]?.data["example"]}
            </pre>
          </div>
        </div>

        <div className="next-button-sec col-xs-12 no-padding">
          <AssessmentButtons
            qsnData={qsnData}
            endTest={endTest}
            type="coding"
            questions={codeQsn?.map((item) => item?.data)}
            currentQuestion={qno}
            selectedAnswers={selectedAnswers}
            descriptiveQsnLength={descriptiveQsnLength ?? 0}
            // selectedAnswers={inputValues}
            setCurrentQuestion={setQno}
            navigation={navigation}
            setTestOutput={setTestOutput}
            testCases={testCases}
            UpdateTestState={() => {}}
          />
        </div>
      </div>
      <div className="col-xs-12 col-md-9 no-padding right-section">
        <div className="col-xs-12 editor-section ">
          <select
            onChange={(e) => handleChangeLanguage(e.target.value)}
            value={language[qno]}
          >
            <option value="python">Python 3</option>
            <option value="javascript">JS</option>
            <option value="java">Java</option>
            <option value="cpp">C++</option>
          </select>
          <Editor
            language={language?.length > 0 ? language[qno] : ""}
            value={inputValues[qno]}
            textAlign={"left"}
            onChange={(val) => handleChangeInput(val)}
            theme="vs-dark"
            options={{
              suggest: {
                showInlineDetails: false,
                showMethods: true,
                showFunctions: true,
                showConstructors: true,
                showFields: true,
                showVariables: true,
                showClasses: true,
                showModules: true,
                showProperties: true,
                showEvents: true,
                showOperators: true,
                showKeywords: true,
                showValues: true,
                showConstants: true,
                showEnums: true,
                showEnumMembers: true,
                showStructs: true,
                showWords: true,
              },
              // suggestFontSize: 12,
              suggestLineHeight: 30,
              cursorStyle: "line",
              renderWhitespace: "all",
              formatOnPaste: false,
              formatOnType: true,
              wordWrap: true,
              autoIndent: "full",
              minimap: { enabled: false },
            }}
          />
          <div className="col-xs-12 no-padding button-section">
            <button
              onClick={() => {
                runCode(
                  core.COMPILER_API.POST_RUN_CODE,
                  codeQsn[qno].data.id,
                  false,
                );
              }}
            >
              Run code
            </button>
            <button
              onClick={(e) => {
                runCode(
                  core.COMPILER_API.POST_TEST_CODE,
                  codeQsn[qno].data.id,
                  true,
                );
              }}
            >
              Run tests
            </button>
          </div>
        </div>
        <div className="col-xs-12 no-padding output-section">
          {processing ? (
            <div className="loader-section col-xs-12 no-padding">
              <PropagateLoader color="blue" />
            </div>
          ) : (
            <>
              <div className="col-xs-3 left-code-output no-padding">
                {Array.isArray(testOutput) ? (
                  testOutput?.map((item, index) => (
                    <div
                      className={
                        item.testPassed
                          ? "list-item passed"
                          : "list-item failed"
                      }
                      key={index}
                    >
                      {item.testPassed ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1400_119898)">
                            <path
                              d="M6.6665 9.99996L8.77874 11.901C9.13832 12.2246 9.697 12.175 9.994 11.7932L13.3332 7.49996M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39747 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39747 18.3333 9.99984 18.3333Z"
                              stroke="#80BB01"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1400_119898">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="18"
                          viewBox="0 0 17 18"
                          fill="none"
                        >
                          <path
                            d="M12.75 13.25L8.50001 9.00001M8.50001 9.00001L4.25 4.75M8.50001 9.00001L12.75 4.75M8.50001 9.00001L4.25 13.25"
                            stroke="#FF475D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      <h4>{"Test " + (index + 1)}</h4>
                      {index > 0 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          width="16px"
                          height="16px"
                        >
                          <path d="M 8 1 C 5.796875 1 4 2.796875 4 5 L 4 6 L 3.5 6 C 2.675781 6 2 6.675781 2 7.5 L 2 12.5 C 2 13.324219 2.675781 14 3.5 14 L 12.5 14 C 13.324219 14 14 13.324219 14 12.5 L 14 7.5 C 14 6.675781 13.324219 6 12.5 6 L 12 6 L 12 5 C 12 2.796875 10.203125 1 8 1 Z M 8 2 C 9.664063 2 11 3.335938 11 5 L 11 6 L 5 6 L 5 5 C 5 3.335938 6.335938 2 8 2 Z M 3.5 7 L 12.5 7 C 12.78125 7 13 7.21875 13 7.5 L 13 12.5 C 13 12.78125 12.78125 13 12.5 13 L 3.5 13 C 3.21875 13 3 12.78125 3 12.5 L 3 7.5 C 3 7.21875 3.21875 7 3.5 7 Z" />
                        </svg>
                      )}
                    </div>
                  ))
                ) : (
                  <>
                    {testOutput?.expectedOutput && (
                      <div
                        className={
                          testOutput?.testPassed
                            ? "list-item passed"
                            : "list-item failed"
                        }
                      >
                        {testOutput?.testPassed ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_1400_119898)">
                              <path
                                d="M6.6665 9.99996L8.77874 11.901C9.13832 12.2246 9.697 12.175 9.994 11.7932L13.3332 7.49996M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39747 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39747 18.3333 9.99984 18.3333Z"
                                stroke="#80BB01"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1400_119898">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                          >
                            <path
                              d="M12.75 13.25L8.50001 9.00001M8.50001 9.00001L4.25 4.75M8.50001 9.00001L12.75 4.75M8.50001 9.00001L4.25 13.25"
                              stroke="#FF475D"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                        <h4>Sample input</h4>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="col-xs-9 right-code-output">
                {/* {testOutput?.stderr?.length <= 1 ? (
                    <>
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Sample input</h4>
                        <span className="response-section col-xs-12">
                          {JSON.stringify(testOutput?.input)}
                        </span>
                      </div>
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Expected output</h4>
                        <span className="response-section col-xs-12">
                          {JSON.stringify(testOutput?.output)}
                        </span>
                      </div>
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Actual output</h4>
                        <span className="response-section col-xs-12">
                          {testOutput?.stdout}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="data-section col-xs-12 no-padding">
                      <h4 className="heading-section">Failed!</h4>
                      <span className="response-section col-xs-12">
                        {testOutput?.stderr}
                      </span>
                    </div>
                  )} */}

                {!Array.isArray(testOutput) ? (
                  testOutput?.stderr === "" ? (
                    <>
                      {testOutput?.stdout && (
                        <div className="data-section col-xs-12 no-padding">
                          <h4 className="heading-section">Stdout</h4>
                          <span className="response-section col-xs-12">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: formateedText(testOutput?.stdout),
                              }}
                            />
                          </span>
                        </div>
                      )}
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Sample input</h4>
                        <span className="response-section col-xs-12">
                          {JSON.stringify(testOutput?.input)}
                        </span>
                      </div>
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Expected output</h4>
                        <span className="response-section col-xs-12">
                          {JSON.stringify(testOutput?.expectedOutput)}
                        </span>
                      </div>
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Actual output</h4>
                        <span className="response-section col-xs-12">
                          {testOutput?.output}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      {testOutput?.expectedOutput && (
                        <>
                          <div className="data-section col-xs-12 no-padding bg-[rgba(246,54,54,0.08)] p-[16px] rounded-lg">
                            <h4 className=" text-[#Ef4743] font-semibold text-[18px]">
                              RunTime Error
                            </h4>
                            <span className=" col-xs-12 no-padding text-[#f8615c] text-[14px] font-semibold mt-[20px]">
                              {testOutput?.stderr}
                            </span>
                          </div>
                          {testOutput?.stdout && (
                            <div className="data-section col-xs-12 no-padding">
                              <h4 className="heading-section">Stdout</h4>
                              <span className="response-section col-xs-12">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: formateedText(testOutput?.stdout),
                                  }}
                                />
                              </span>
                            </div>
                          )}
                          <div className="data-section col-xs-12 no-padding">
                            <h4 className="heading-section">Sample input</h4>
                            <span className="response-section col-xs-12">
                              {JSON.stringify(testOutput?.input)}
                            </span>
                          </div>
                          <div className="data-section col-xs-12 no-padding">
                            <h4 className="heading-section">Expected output</h4>
                            <span className="response-section col-xs-12">
                              {JSON.stringify(testOutput?.expectedOutput)}
                            </span>
                          </div>
                          <div className="data-section col-xs-12 no-padding">
                            <h4 className="heading-section">Actual output</h4>
                            <span className="response-section col-xs-12">
                              {testOutput?.output}
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  )
                ) : (
                  <>
                    <div className="data-section col-xs-12 no-padding">
                      <h4 className="heading-section">Input</h4>
                      <span className="response-section col-xs-12">
                        {JSON.stringify(testOutput[0]?.input)}
                      </span>
                    </div>
                    <div className="data-section col-xs-12 no-padding">
                      <h4 className="heading-section">Expected output</h4>
                      <span className="response-section col-xs-12">
                        {JSON.stringify(testOutput[0]?.expectedOutput)}
                      </span>
                    </div>
                    {testOutput?.stdout && (
                      <div className="data-section col-xs-12 no-padding">
                        <h4 className="heading-section">Stdout</h4>
                        <span className="response-section col-xs-12">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: formateedText(testOutput?.stdout),
                            }}
                          />
                        </span>
                      </div>
                    )}
                    <div className="data-section col-xs-12 no-padding">
                      <h4 className="heading-section">Actual output</h4>
                      <span className="response-section col-xs-12">
                        {testOutput[0]?.output}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
