export const mail = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-full h-full"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
    />
  </svg>
);

export const notes = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-full h-full"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
    />
  </svg>
);

export const calendar = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-full h-full"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
    />
  </svg>
);

export const report = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-full h-full"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
    />
  </svg>
);

export const activity = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.2}
    stroke="currentColor"
    className="w-full h-full"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z"
    />
  </svg>
);

export const filterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
    />
  </svg>
);

export const ZoomIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={width}
    height={height}
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <g fillRule="nonzero">
      <path
        d="M100 50c0 5.347-.43 10.59-1.251 15.702-2.718 16.923-16.124 30.329-33.047 33.047A98.964 98.964 0 0150 100c-5.346 0-10.589-.43-15.702-1.251C17.375 96.031 3.969 82.625 1.251 65.702A98.955 98.955 0 010 50c0-5.347.43-10.59 1.251-15.702C3.969 17.375 17.375 3.969 34.298 1.251A98.973 98.973 0 0150 0c5.347 0 10.589.43 15.702 1.251 16.923 2.718 30.329 16.124 33.047 33.047A98.955 98.955 0 01100 50z"
        fill="url(#prefix___Linear1)"
        transform="matrix(5.11 0 0 5.11 .709 .331)"
      />
      <path
        d="M153.304 294.958h-63.38a10.928 10.928 0 01-10.122-6.765 10.923 10.923 0 012.376-11.937l43.9-43.9H94.615c-8.646 0-15.651-7.006-15.651-15.652h58.448c4.45 0 8.421 2.652 10.123 6.76a10.931 10.931 0 01-2.371 11.942l-43.906 43.9h36.394c8.646 0 15.652 7.006 15.652 15.652zm280.15-48.908c0-16.827-13.69-30.522-30.516-30.522-9.004 0-17.104 3.92-22.694 10.139-5.595-6.22-13.695-10.139-22.693-10.139-16.833 0-30.523 13.69-30.523 30.522v48.908c8.647 0 15.652-7.006 15.652-15.652V246.05c0-8.201 6.669-14.87 14.87-14.87 8.197 0 14.866 6.669 14.866 14.87v33.256c0 8.646 7.005 15.652 15.651 15.652V246.05c0-8.201 6.669-14.87 14.87-14.87 8.197 0 14.866 6.669 14.866 14.87v33.256c0 8.646 7.01 15.652 15.651 15.652V246.05zm-114.249 9.781c0 22.26-18.043 40.303-40.302 40.303-22.26 0-40.303-18.044-40.303-40.303 0-22.26 18.043-40.303 40.303-40.303 22.259 0 40.302 18.044 40.302 40.303zm-15.652 0c0-13.613-11.037-24.65-24.65-24.65-13.614 0-24.651 11.037-24.651 24.65 0 13.613 11.037 24.65 24.65 24.65 13.614 0 24.651-11.037 24.651-24.65zm-71.213 0c0 22.26-18.043 40.303-40.297 40.303-22.26 0-40.303-18.044-40.303-40.303 0-22.26 18.044-40.303 40.303-40.303 22.254 0 40.297 18.044 40.297 40.303zm-15.652 0c0-13.613-11.032-24.65-24.645-24.65-13.618 0-24.65 11.037-24.65 24.65 0 13.613 11.032 24.65 24.65 24.65 13.613 0 24.645-11.037 24.645-24.65z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="prefix___Linear1"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-60 94.635 27.31) scale(105.3358)"
      >
        <stop offset="0" stopColor="#0845bf" />
        <stop offset=".6" stopColor="#0b5cff" />
        <stop offset="1" stopColor="#4f90ee" />
      </linearGradient>
    </defs>
  </svg>
);
export const GoogleMeet = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Google Meet"
    width={width}
    height={height}
    role="img"
    viewBox="0 0 512 512"
  >
    <rect width="512" height="512" rx="15%" fill="#ffffff" />
    <path d="M166 106v90h-90" fill="#ea4335" />
    <path d="M166 106v90h120v62l90-73v-49q0-30-30-30" fill="#ffba00" />
    <path d="M164 406v-90h122v-60l90 71v49q0 30-30 30" fill="#00ac47" />
    <path d="M286 256l90-73v146" fill="#00832d" />
    <path
      d="M376 183l42-34c9-7 18-7 18 7v200c0 14-9 14-18 7l-42-34"
      fill="#00ac47"
    />
    <path d="M76 314v62q0 30 30 30h60v-92" fill="#0066da" />
    <path d="M76 196h90v120h-90" fill="#2684fc" />
  </svg>
);

export const GoogleCalendar = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Google Calendar"
    width={width}
    height={height}
    role="img"
    viewBox="0 0 200 200"
  >
    <rect width="200" height="200" rx="30" fill="#ffffff" />
    <g transform="translate(3.75 3.75)">
      <path
        fill="#FFFFFF"
        d="M148.882,43.618l-47.368-5.263l-57.895,5.263L38.355,96.25l5.263,52.632l52.632,6.579l52.632-6.579    l5.263-53.947L148.882,43.618z"
      />
      <path
        fill="#1A73E8"
        d="M65.211,125.276c-3.934-2.658-6.658-6.539-8.145-11.671l9.132-3.763c0.829,3.158,2.276,5.605,4.342,7.342    c2.053,1.737,4.553,2.592,7.474,2.592c2.987,0,5.553-0.908,7.697-2.724s3.224-4.132,3.224-6.934c0-2.868-1.132-5.211-3.395-7.026    s-5.105-2.724-8.5-2.724h-5.276v-9.039H76.5c2.921,0,5.382-0.789,7.382-2.368c2-1.579,3-3.737,3-6.487    c0-2.447-0.895-4.395-2.684-5.855s-4.053-2.197-6.803-2.197c-2.684,0-4.816,0.711-6.395,2.145s-2.724,3.197-3.447,5.276    l-9.039-3.763c1.197-3.395,3.395-6.395,6.618-8.987c3.224-2.592,7.342-3.895,12.342-3.895c3.697,0,7.026,0.711,9.974,2.145    c2.947,1.434,5.263,3.421,6.934,5.947c1.671,2.539,2.5,5.382,2.5,8.539c0,3.224-0.776,5.947-2.329,8.184    c-1.553,2.237-3.461,3.947-5.724,5.145v0.539c2.987,1.25,5.421,3.158,7.342,5.724c1.908,2.566,2.868,5.632,2.868,9.211    s-0.908,6.776-2.724,9.579c-1.816,2.803-4.329,5.013-7.513,6.618c-3.197,1.605-6.789,2.421-10.776,2.421    C73.408,129.263,69.145,127.934,65.211,125.276z"
      />
      <path
        fill="#1A73E8"
        d="M121.25,79.961l-9.974,7.25l-5.013-7.605l17.987-12.974h6.895v61.197h-9.895L121.25,79.961z"
      />
      <path
        fill="#EA4335"
        d="M148.882,196.25l47.368-47.368l-23.684-10.526l-23.684,10.526l-10.526,23.684L148.882,196.25z"
      />
      <path
        fill="#34A853"
        d="M33.092,172.566l10.526,23.684h105.263v-47.368H43.618L33.092,172.566z"
      />
      <path
        fill="#4285F4"
        d="M12.039-3.75C3.316-3.75-3.75,3.316-3.75,12.039v136.842l23.684,10.526l23.684-10.526V43.618h105.263    l10.526-23.684L148.882-3.75H12.039z"
      />
      <path
        fill="#188038"
        d="M-3.75,148.882v31.579c0,8.724,7.066,15.789,15.789,15.789h31.579v-47.368H-3.75z"
      />
      <path
        fill="#FBBC04"
        d="M148.882,43.618v105.263h47.368V43.618l-23.684-10.526L148.882,43.618z"
      />
      <path
        fill="#1967D2"
        d="M196.25,43.618V12.039c0-8.724-7.066-15.789-15.789-15.789h-31.579v47.368H196.25z"
      />
    </g>
  </svg>
);

export const OutlookCalendar = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Outlook Calendar"
    width={width}
    height={height}
    role="img"
    viewBox="0 0 48 48"
  >
    <path
      fill="#1976d2"
      d="M28,13h14.533C43.343,13,44,13.657,44,14.467v19.066C44,34.343,43.343,35,42.533,35H28V13z"
    />
    <rect width="14" height="15.542" x="28" y="17.958" fill="#fff" />
    <polygon fill="#1976d2" points="27,44 4,39.5 4,8.5 27,4" />
    <path
      fill="#fff"
      d="M15.25,16.5c-3.176,0-5.75,3.358-5.75,7.5s2.574,7.5,5.75,7.5S21,28.142,21,24 S18.426,16.5,15.25,16.5z M15,28.5c-1.657,0-3-2.015-3-4.5s1.343-4.5,3-4.5s3,2.015,3,4.5S16.657,28.5,15,28.5z"
    />
    <rect width="2.7" height="2.9" x="28.047" y="29.737" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="31.448" y="29.737" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="34.849" y="29.737" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="28.047" y="26.159" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="31.448" y="26.159" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="34.849" y="26.159" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="38.25" y="26.159" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="28.047" y="22.706" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="31.448" y="22.706" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="34.849" y="22.706" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="38.25" y="22.706" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="31.448" y="19.112" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="34.849" y="19.112" fill="#1976d2" />
    <rect width="2.7" height="2.9" x="38.25" y="19.112" fill="#1976d2" />
  </svg>
);

export const Outlook = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Outlook"
    width={width}
    height={height}
    role="img"
    viewBox="0 0 48 48"
  >
    <rect width="48" height="48" rx="15%" fill="#ffffff" />
    <path
      fill="#0c4999"
      d="M33.919 8.84h9.046V7.732C42.965 6.775 42.19 6 41.234 6H17.667c-.956 0-1.732.775-1.732 1.732V8.84h18.984z"
    />
    <path
      fill="#0f73d9"
      d="M33.919 33.522h7.314c.956 0 1.732-.775 1.732-1.732v-6.827h-9.046v8.559z"
    />
    <path
      fill="#0f439d"
      d="M15.936 24.964v6.827c0 .956.775 1.732 1.732 1.732h7.273v-8.558H15.936z"
    />
    <path fill="#2ecdfd" d="M33.919 8.84h9.046v8.027h-9.046z" />
    <path fill="#1c5fb0" d="M15.936 8.84h9.005v8.027h-9.005z" />
    <path fill="#1467c7" d="M24.94 24.964h8.979v8.558H24.94z" />
    <path fill="#1690d5" d="M24.94 8.84h8.979v8.027H24.94z" />
    <path fill="#1bb4ff" d="M33.919 16.867h9.046v8.096h-9.046z" />
    <path fill="#074daf" d="M15.936 16.867h9.005v8.096h-9.005z" />
    <path fill="#2076d4" d="M24.94 16.867h8.979v8.096H24.94z" />
    <path
      fill="#00488d"
      d="M22.319 34H5.681A1.681 1.681 0 0 1 4 32.319V15.681C4 14.753 4.753 14 5.681 14h16.638A1.681 1.681 0 0 1 24 15.681v16.638A1.681 1.681 0 0 1 22.319 34z"
    />
    <path
      fill="#fff"
      d="M13.914 18.734c-3.131 0-5.017 2.392-5.017 5.343 0 2.951 1.879 5.342 5.017 5.342 3.139 0 5.017-2.392 5.017-5.342 0-2.951-1.886-5.343-5.017-5.343zm0 8.882c-1.776 0-2.838-1.584-2.838-3.539s1.067-3.539 2.838-3.539c1.771 0 2.839 1.585 2.839 3.539s-1.064 3.539-2.839 3.539z"
    />
  </svg>
);

export const TeamsLogo = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Teams"
    width={width}
    height={height}
    role="img"
    viewBox="0 0 48 48"
  >
    <path
      fill="#5059c9"
      d="M44,22v8c0,3.314-2.686,6-6,6s-6-2.686-6-6V20h10C43.105,20,44,20.895,44,22z M38,16 c2.209,0,4-1.791,4-4c0-2.209-1.791-4-4-4s-4,1.791-4,4C34,14.209,35.791,16,38,16z"
    />
    <path
      fill="#7b83eb"
      d="M35,22v11c0,5.743-4.841,10.356-10.666,9.978C19.019,42.634,15,37.983,15,32.657V20h18 C34.105,20,35,20.895,35,22z M25,17c3.314,0,6-2.686,6-6s-2.686-6-6-6s-6,2.686-6,6S21.686,17,25,17z"
    />
    <circle cx="25" cy="11" r="6" fill="#7b83eb" />
    <path
      d="M26,33.319V20H15v12.657c0,1.534,0.343,3.008,0.944,4.343h6.374C24.352,37,26,35.352,26,33.319z"
      opacity=".05"
    />
    <path
      d="M15,20v12.657c0,1.16,0.201,2.284,0.554,3.343h6.658c1.724,0,3.121-1.397,3.121-3.121V20H15z"
      opacity=".07"
    />
    <path
      d="M24.667,20H15v12.657c0,0.802,0.101,1.584,0.274,2.343h6.832c1.414,0,2.56-1.146,2.56-2.56V20z"
      opacity=".09"
    />
    <linearGradient
      id="DqqEodsTc8fO7iIkpib~Na_zQ92KI7XjZgR_gr1"
      x1="4.648"
      x2="23.403"
      y1="14.648"
      y2="33.403"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#5961c3" />
      <stop offset="1" stopColor="#3a41ac" />
    </linearGradient>
    <path
      fill="url(#DqqEodsTc8fO7iIkpib~Na_zQ92KI7XjZgR_gr1)"
      d="M22,34H6c-1.105,0-2-0.895-2-2V16c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2v16 C24,33.105,23.105,34,22,34z"
    />
    <path
      fill="#fff"
      d="M18.068,18.999H9.932v1.72h3.047v8.28h2.042v-8.28h3.047V18.999z"
    />
  </svg>
);
