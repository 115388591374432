import React, { useState, useCallback, memo } from "react";
import TextAreaBox from "../../../layouts/TextAreaBox";
import { ScaleLoader } from "react-spinners";
const core = require("../../../lib/core");

function areEqual(prevProps, nextProps) {
  return (
    prevProps.questions === nextProps.questions &&
    prevProps.selectedAnswers === nextProps.selectedAnswers &&
    prevProps.navigation.currentPage === nextProps.navigation.currentPage
  );
}

const AutonomusInterview = ({
  questions,
  endTest,
  navigation,
  selectedAnswers,
  UpdateTestState,
  countSkipQsn,
  setCountSkipQsn,
}) => {
  const [curQsnIndex, setCurQsnIndex] = useState(0);
  const [responses, setResponses] = useState([]);
  const [loader, setLoader] = useState(false);

  const getCookie = useCallback((name) => {
    const cookieName = `${name}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    for (let cookie of cookieArray) {
      cookie = cookie.trim();
      if (cookie.startsWith(cookieName)) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  }, []);

  const assessmentId = getCookie("assessmentId");

  // Simplified trackResponse function that returns a promise
  const trackResponse = useCallback(
    async (questionId, answer) => {
      if (!assessmentId || !answer) return;

      try {
        const response = {
          question_id: questionId,
          answer: answer,
          type: "descriptive",
          answered_at: new Date().toISOString(),
          test_cases: false,
        };

        await core.API(
          core.API_METHODS.POST,
          `${core.CANDIDATE_API.POST_TRACK_CANDIDATE_ANSWER}${assessmentId}/track_response`,
          1,
          response,
        );
      } catch (error) {
        console.error("Error tracking response:", error);
        throw error;
      }
    },
    [assessmentId],
  );

  const handleNext = useCallback(async () => {
    if (!questions[curQsnIndex]) return;

    setLoader(true);
    try {
      const currentResponse = responses[curQsnIndex]?.answer;

      // Track the current response if it exists
      if (currentResponse) {
        await trackResponse(questions[curQsnIndex]._id, currentResponse);
        setCountSkipQsn((prevCount) => prevCount + 1);
      }

      // If it's the last question, end the test immediately
      if (curQsnIndex === questions.length - 1) {
        await endTest();
        return;
      }

      // Move to next question
      setCurQsnIndex((prevIndex) => prevIndex + 1);
    } catch (error) {
      console.error("Error in handleNext:", error);
    } finally {
      setLoader(false);
    }
  }, [
    curQsnIndex,
    questions,
    responses,
    trackResponse,
    endTest,
    setCountSkipQsn,
  ]);

  const handleBack = useCallback(() => {
    if (curQsnIndex > 0) {
      setCurQsnIndex((prevIndex) => prevIndex - 1);
    } else if (navigation?.changePage) {
      const currentSectionIndex = navigation.allPages.indexOf("interview");
      if (currentSectionIndex > 0) {
        navigation.changePage();
      }
    }
  }, [curQsnIndex, navigation]);

  const handleResponseChange = useCallback(
    (event) => {
      const updatedResponse = event.target.value;
      setResponses((prevResponses) => {
        const newResponses = [...prevResponses];
        newResponses[curQsnIndex] = {
          question_id: questions[curQsnIndex]._id,
          answer: updatedResponse,
          type: "descriptive",
          answered_at: new Date().toISOString(),
          test_cases: false,
        };
        return newResponses;
      });
    },
    [curQsnIndex, questions],
  );

  const renderQuestion = useCallback(() => {
    if (questions.length === 0) return null;

    const currentQuestion = questions[curQsnIndex];
    return (
      <span className="block text-lg font-semibold text-gray-700 my-10">
        {`Q${curQsnIndex + 1}. ${currentQuestion.question}`}
      </span>
    );
  }, [questions, curQsnIndex]);

  return (
    <section
      id="interviewSection"
      className="flex flex-col h-screen overflow-y-auto pl-0 pr-1"
    >
      <div className="w-full justify-start max-w-6xl bg-white p-6 rounded-lg shadow-sm overflow-y-auto">
        <div className="mb-10">
          <p className="text-sm text-gray-700 mb-4">
            Please read the question carefully and provide your detailed answer.
            Your screen, audio, and face are being recorded.
          </p>
          {renderQuestion()}
          <TextAreaBox
            placeholder="Write your answer here ..."
            value={responses[curQsnIndex]?.answer || ""}
            onChange={handleResponseChange}
            size="350px"
          />
        </div>
      </div>
      <div className="flex justify-start mt-24 w-full max-w-6xl space-x-4">
        <button
          className="px-7 py-2 border border-gray-500 text-sm font-medium text-gray-800 bg-white rounded-md hover:bg-blue-950 hover:text-white transition-all duration-300"
          onClick={handleBack}
        >
          Back
        </button>
        <button
          className="px-7 py-2 text-sm font-medium text-white rounded-md bg-blue-900 hover:bg-blue-950 transition-all duration-300"
          onClick={handleNext}
          disabled={loader}
        >
          {loader ? (
            <ScaleLoader color="white" loading={loader} height={12} />
          ) : curQsnIndex === questions.length - 1 ? (
            "Submit"
          ) : (
            "Next"
          )}
        </button>
      </div>
    </section>
  );
};

export default memo(AutonomusInterview, areEqual);
