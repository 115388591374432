import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import recruiterLogo from "../../components/assets/LogoWhite.png";
import InitialsLogo from "../../components/all/InitialsLogo";
import PercentDonut from "../../components/analytics/PercentDonut";
import { useParams } from "react-router";
import { Editor } from "@monaco-editor/react";
import { ICONS } from "../../components/assets/componentAssets";

const core = require("../../lib/core");
function AssessmentEvalReport() {
  const params = useParams();
  const [data, setData] = useState({});
  const [openSection, setOpenSection] = useState([]);
  const [openQuestion, setOpenQuestion] = useState({
    mcq: [],
    coding: [],
    project: [],
  });
  const colors = ["#1369E9", "#80BB01", "#FF7324"];
  const chartSizes = [220, 170, 120];
  useEffect(() => {
    getReport();
  }, [params]);

  async function getReport() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_ASSESSMENT_REPORT + params.id,
        1,
      );
      console.log(json.data);
      if (json?.data) {
        setData(json.data);
      } else {
        console.log(json.error);
      }
    } catch (err) {
      console.log(err);
    }
  }
  function scoreLevel(score) {
    switch (Math.round(score / 10)) {
      case 0: {
        return "No";
      }
      case 1: {
        return "Extremely poor";
      }
      case 2: {
        return "Very poor";
      }
      case 3: {
        return "Poor";
      }
      case 4: {
        return "Below average";
      }
      case 5: {
        return "Average";
      }
      case 6: {
        return "Above average";
      }
      case 7: {
        return "Good";
      }
      case 8: {
        return "Very Good";
      }
      case 9: {
        return "Excellent";
      }
      case 10: {
        return "Perfect";
      }
    }
  }
  function sectionLabel(label) {
    switch (label) {
      case "mcq": {
        return "Multiple choice questions";
      }
      default: {
        return String(label).charAt(0).toUpperCase() + String(label).slice(1);
      }
    }
  }
  function dateFormat(date) {
    return (
      new Date(date).toString().split(" ")[2] +
      " " +
      new Date(date).toString().split(" ")[1] +
      ", " +
      new Date(date).toString().split(" ")[3]
    );
  }
  function incorrectQuestion(section) {
    let attemptedQuestion = +data?.attemptedQuestions[section].split("/")[0];
    let correctQuestion = +data?.correctQuestions[section];
    return attemptedQuestion - correctQuestion > -1
      ? attemptedQuestion - correctQuestion
      : 0;
  }
  function skippedQuestion(section) {
    let attemptedQuestion = +data?.attemptedQuestions[section].split("/")[0];
    let totalQuestion = +data[section + "Questions"]?.length;
    return totalQuestion - attemptedQuestion > -1
      ? totalQuestion - attemptedQuestion
      : 0;
  }

  // console.log(data);

  const formatCode = (code) => {
    const lines = code?.split("\n");
    const formattedCode = lines?.map((line, index) => {
      const indentation = line?.match(/^\s*/)[0];
      const indentationLevel = indentation?.length / 4; // Assuming each tab is equivalent to 4 spaces
      const spaces = "\u00A0".repeat(indentationLevel * 4); // Non-breaking spaces

      const formattedLine = line
        ?.replace(/\\n/g, "<br>")
        ?.replace(/\\t/g, spaces)
        ?.replace(/^\s*/, spaces);

      return (
        <span key={index} dangerouslySetInnerHTML={{ __html: formattedLine }} />
      );
    });

    return formattedCode;
  };

  const testOutput = [
    {
      input: "[10, 20, 30, 40, 50]",
      expectedOutput: "50,40",
      output: "10\n",
      testPassed: true,
    },
    {
      testPassed: false,
      input: "[10, 20, 30, 40, 50]",
      expectedOutput: "50,40",
      output: "10\n",
    },
    {
      testPassed: false,
      input: "[10, 20, 30, 40, 50]",
      expectedOutput: "50,40",
      output: "10\n",
    },
    {
      testPassed: false,
      input: "[10, 20, 30, 40, 50]",
      expectedOutput: "50,40",
      output: "10\n",
    },
    {
      testPassed: false,
      input: "[10, 20, 30, 40, 50]",
      expectedOutput: "50,40",
      output: "10\n",
    },
  ];

  const loc = useLocation();
  // console.log(window.location.href);

  const handleEmailButtonClick = () => {
    const email = "";
    const subject = `Assessment report of ${data?.assessment?.name}`;
    const body = window.location.href;
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const getCandidateAnswer = (questionId) => {
    if (!data.descriptive_answers) return null;

    const answer = data.descriptive_answers.find(
      (answer) => answer.question_id === questionId,
    );
    return answer;
  };

  return (
    // data?.assessment && (
    <div
      className="page h-full overflow-scroll col-xs-12 no-padding"
      id="eval-report"
    >
      <header className="col-xs-12 navbar">
        <div className="col-xs-12 no-padding navbar-section">
          <img src={recruiterLogo} alt="" />
          {/* <button
              data-toggle="modal"
              data-target="#confirm-modal"
            // onClick={handleEmailButtonClick}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M6.19249 7.69518C5.71364 6.73934 4.72503 6.08317 3.58317 6.08317C1.97234 6.08317 0.666504 7.38901 0.666504 8.99984C0.666504 10.6107 1.97234 11.9165 3.58317 11.9165C4.72503 11.9165 5.71364 11.2603 6.19249 10.3045M6.19249 7.69518C6.38915 8.08775 6.49984 8.53087 6.49984 8.99984C6.49984 9.46881 6.38915 9.91193 6.19249 10.3045M6.19249 7.69518L11.8072 4.88783M6.19249 10.3045L11.8072 13.1118M11.8072 4.88783C12.286 5.84367 13.2746 6.49984 14.4165 6.49984C16.0273 6.49984 17.3332 5.194 17.3332 3.58317C17.3332 1.97234 16.0273 0.666504 14.4165 0.666504C12.8057 0.666504 11.4998 1.97234 11.4998 3.58317C11.4998 4.05214 11.6105 4.49526 11.8072 4.88783ZM11.8072 13.1118C11.6105 13.5044 11.4998 13.9475 11.4998 14.4165C11.4998 16.0273 12.8057 17.3332 14.4165 17.3332C16.0273 17.3332 17.3332 16.0273 17.3332 14.4165C17.3332 12.8057 16.0273 11.4998 14.4165 11.4998C13.2746 11.4998 12.286 12.156 11.8072 13.1118Z" stroke="#252E4B" strokeLinecap="round" />
              </svg>{" "}
              share</button> */}
        </div>
      </header>
      <main className="main-content col-xs-12">
        <div className="top-section col-xs-12 no-padding">
          <h2>{data?.assessment}</h2>
          <h1>Assessment report</h1>
          <div className="col-xs-9 no-padding user">
            <div className="col-xs-6 no-padding left flex">
              {data?.candidate?.pic ? (
                // <img src={data?.candidate?.pic} />
                <InitialsLogo
                  className="img-responsive"
                  str={data?.name ? data?.name : "N"}
                  width={40}
                  height={40}
                  bgRounded="12px"
                  fontSize={14}
                />
              ) : (
                <InitialsLogo
                  className="img-responsive"
                  str={data?.pic ?? "C"}
                  width={40}
                  height={40}
                  bgRounded="12px"
                  fontSize={14}
                />
              )}
              <div
                style={{
                  width: "calc(100% - 60px)",
                  float: "left",
                  marginLeft: "8px",
                }}
              >
                <span className="col-xs-12 no-padding name">
                  {data?.name ? data?.name : "Not Mentioned"}
                </span>

                <span className="col-xs-12 no-padding email">
                  {data?.email ?? "Not Mentioned"}
                </span>
              </div>
            </div>
            <div className="col-xs-6 no-padding right">
              {/* <a href={data?.resume} target="blank" className="mr-2">
                <button className="resume">Resume</button>
              </a>
              <a href={data?.video} target="blank">
                <button className="resume">Video</button>
              </a> */}
              {/* <a href="">
                  <button className="profile">View profile</button>
                </a> */}
            </div>
          </div>
        </div>
        <div className="main-card col-xs-12 no-padding overall">
          <div className="col-xs-12 no-padding top">
            <div className="percent col-xs-6 no-padding">
              {/* <h1>{data?.overallScore}</h1> */}
              <h2>{scoreLevel(+data?.total?.totalPercentage)} total score</h2>
            </div>
            <div className="date col-xs-6 no-padding">
              <span className="date-label">Attended date</span>
              <span className="date">{dateFormat(data?.updatedAt)}</span>
            </div>
          </div>
          <div className="col-xs-12 no-padding bottom mt-5">
            <div className="col-xs-8 no-padding score-table">
              <div className="heading-row col-xs-12">
                <div className="col-xs-6 no-padding">Section</div>
                <div className="col-xs-4 no-padding">Attempted questions</div>
                <div className="col-xs-2 no-padding">Score</div>
              </div>
              {data?.questionsAttempted &&
                Object.keys(data?.questionsAttempted).map((key, idx) => (
                  <div className="data-row col-xs-12" key={idx}>
                    <div className="col-xs-6 no-padding">
                      <span
                        className="legend-color"
                        style={{
                          background: colors[idx],
                        }}
                      />
                      {sectionLabel(key)}
                    </div>
                    <div className="col-xs-4 no-padding">
                      {data?.questionsAttempted[key].attempted}
                    </div>
                    <div className="col-xs-2 no-padding">
                      {Number(
                        (data?.questionsAttempted[key].score /
                          data?.questionsAttempted[key].fullScore) *
                          100,
                      )?.toFixed(2)}{" "}
                      %
                    </div>
                  </div>
                ))}
            </div>
            <div className="graph col-xs-4 no-padding">
              <span className="text-[18px]">
                {Number(data?.total?.totalPercentage)?.toFixed(1)} %
              </span>
              {data?.questionsAttempted &&
                Object.keys(data?.questionsAttempted).map((key, idx) => (
                  <PercentDonut
                    key={idx}
                    id={"assessment_score_section_" + idx}
                    chartSize={chartSizes[idx]}
                    radius={chartSizes[idx] / 2}
                    width={15}
                    cornerRadius={10}
                    percentData={
                      (data.questionsAttempted[key]?.score /
                        data.questionsAttempted[key]?.fullScore) *
                      100
                    }
                    fontSize={0}
                    customColor={colors[idx]}
                    customTooltipText={
                      sectionLabel(key) +
                      " percentage = " +
                      Number(
                        (data.questionsAttempted[key]?.score /
                          data.questionsAttempted[key]?.fullScore) *
                          100,
                      )?.toFixed(2) +
                      "%"
                    }
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="heading col-xs-12 no-padding">
          <h2 className="col-xs-6 no-padding">Detail evaluation</h2>
          <div className="col-xs-6 no-padding">
            {data?.screenRecording && (
              <a href={data?.screenRecording}>
                <button>Screen recording</button>
              </a>
            )}
            {data?.video && (
              <a href={data?.video}>
                <button>Interview Recording</button>
              </a>
            )}
          </div>
        </div>
        {data?.mcqQuestionsWithAnswers &&
          data?.mcqQuestionsWithAnswers?.length > 0 && (
            <div className="main-card col-xs-12 no-padding mcq">
              <div
                className="header-banner col-xs-12 no-padding"
                onClick={() => {
                  if (openSection.includes("mcq")) {
                    setOpenSection((prev) => prev.filter((d) => d != "mcq"));
                    setOpenQuestion((prev) => {
                      return { ...prev, mcq: [] };
                    });
                  } else {
                    setOpenSection((prev) => [...prev, "mcq"]);
                  }
                }}
              >
                <div className="col-xs-6 no-padding">
                  <h3>Multiple choice questions (MCQ)</h3>
                  <p>{data?.total?.mcqScore} score</p>
                </div>
                <div className="col-xs-6 no-padding right">
                  <span className="total-questions">
                    <label>Total questions</label>
                    <p>{data?.questionsAttempted?.mcq?.total} questions</p>
                  </span>
                  <span className="correct-questions">
                    <p>{data?.questionsAttempted?.mcq?.correct}</p>
                    <label>Correct</label>
                  </span>
                  <span className="incorrect-questions">
                    <p>{data?.questionsAttempted?.mcq?.incorrect}</p>
                    <label>Incorrect</label>
                  </span>
                  <span className="skip-questions">
                    <p>{data?.questionsAttempted?.mcq?.skipped}</p>
                    <label>Skipped</label>
                  </span>
                </div>
              </div>
              {/* {openSection.includes("mcq") && ( */}
              <div className="mcq question-section col-xs-12 no-padding">
                <div className="header-row col-xs-12 no-padding">
                  <span className="qu">Questions</span>
                  {/* <span className="ans">Answers</span> */}
                </div>
                {data?.mcqQuestionsWithAnswers?.map((qu, i) => (
                  <div className="col-xs-12 no-padding question-row" key={i}>
                    <div
                      className="col-xs-12 no-padding question"
                      onClick={() => {
                        if (openQuestion?.mcq?.includes(i)) {
                          setOpenQuestion((prev) => {
                            let new_mcq = prev?.mcq;
                            new_mcq = new_mcq.filter((d) => d != i);
                            return { ...prev, mcq: new_mcq };
                          });
                        } else {
                          setOpenQuestion((prev) => {
                            let new_mcq = prev?.mcq;
                            new_mcq.push(i);
                            return { ...prev, mcq: new_mcq };
                          });
                        }
                      }}
                    >
                      <span className="qu">
                        {/* {i + 1}. {qu?.question} */}
                        {i + 1}. {formatCode(qu?.question)}
                      </span>
                      {/* <span className="ans">Answers</span> */}
                    </div>
                    {/* {openQuestion.mcq.includes(i) && ( */}
                    <ol className="col-xs-12 qu-ans">
                      {qu?.options?.map((option, ix) => (
                        <li
                          key={ix}
                          className={
                            qu?.answer?.[0] == ix && option != qu?.correctAnswer
                              ? "wrong"
                              : option == qu?.correctAnswer
                                ? "correct"
                                : ""
                          }
                        >
                          <input
                            type="checkbox"
                            name={"answer" + ix}
                            readOnly
                            checked={qu?.answer?.[0] == ix}
                          />
                          <label htmlFor={"answer" + ix}>{option}</label>
                        </li>
                      ))}
                    </ol>
                    {/* // )} */}
                  </div>
                ))}
              </div>
              {/* // )} */}
            </div>
          )}
        {data?.codingQuestionsWithAnswers &&
          data?.codingQuestionsWithAnswers?.length > 0 && (
            <div className="main-card col-xs-12 no-padding coding">
              <div
                className="header-banner col-xs-12 no-padding"
                onClick={() => {
                  if (openSection.includes("coding")) {
                    setOpenSection((prev) => prev.filter((d) => d != "coding"));
                    setOpenQuestion((prev) => {
                      return { ...prev, coding: [] };
                    });
                  } else {
                    setOpenSection((prev) => [...prev, "coding"]);
                  }
                }}
              >
                <div className="col-xs-6 no-padding">
                  <h3>Coding</h3>
                  {data?.total?.codingScore} Score
                </div>
                <div className="col-xs-6 no-padding right">
                  <span className="total-questions">
                    <label>Total questions</label>
                    <p>
                      {data?.questionsAttempted?.coding_question?.total}{" "}
                      questions
                    </p>
                  </span>
                  <span className="correct-questions">
                    <p>{data?.questionsAttempted?.coding_question?.correct}</p>
                    <label>Correct</label>
                  </span>
                  <span className="incorrect-questions">
                    <p>
                      {data?.questionsAttempted?.coding_question?.incorrect}
                    </p>
                    <label>Incorrect</label>
                  </span>
                  <span className="skip-questions">
                    <p>{data?.questionsAttempted?.coding_question?.skipped}</p>
                    <label>Skipped</label>
                  </span>
                </div>
              </div>
              {/* {openSection.includes("coding") && ( */}
              <div className="coding question-section col-xs-12 no-padding">
                <div className="header-row col-xs-12 no-padding">
                  <span className="qu">Questions</span>
                  {/* <span className="ans">Answers</span> */}
                </div>
                {data?.codingQuestionsWithAnswers?.map((qu, i) => (
                  <div className="col-xs-12 no-padding question-row" key={i}>
                    <div
                      className="col-xs-12 no-padding question"
                      key={i}
                      onClick={() => {
                        if (openQuestion.coding.includes(i)) {
                          setOpenQuestion((prev) => {
                            let new_coding = prev.coding;
                            new_coding = new_coding.filter((d) => d != i);
                            return { ...prev, coding: new_coding };
                          });
                        } else {
                          setOpenQuestion((prev) => {
                            let new_coding = prev.coding;
                            new_coding.push(i);
                            return { ...prev, coding: new_coding };
                          });
                        }
                      }}
                    >
                      <span className="qu">
                        {i + 1}. {qu?.question}
                      </span>
                      {/* <span className="ans">Answers</span> */}
                    </div>
                    {/* {openQuestion.coding.includes(i) && ( */}
                    <div className="col-xs-12 no-padding qu-ans">
                      <div className="editor-wrapper col-xs-12 no-padding">
                        <Editor
                          value={qu?.answer}
                          textAlign={"left"}
                          height={400}
                          theme="vs-dark"
                          options={{
                            cursorStyle: "line",
                            renderWhitespace: "all",
                            formatOnPaste: false,
                            formatOnType: true,
                            wordWrap: true,
                            autoIndent: "full",
                            minimap: { enabled: false },
                          }}
                        />
                      </div>
                      <div className="output-wrapper col-xs-12 no-padding">
                        <span className="label">Test cases</span>
                        <div className="col-xs-12 no-padding output-section">
                          <>
                            <div className="col-xs-3 left-code-output no-padding">
                              {Array.isArray(qu?.test_cases) ? (
                                qu?.test_cases?.map((item, index) => (
                                  <div
                                    className={
                                      item.testPassed
                                        ? "list-item passed"
                                        : "list-item failed"
                                    }
                                    key={index}
                                  >
                                    {item.testPassed ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <g clipPath="url(#clip0_1400_119898)">
                                          <path
                                            d="M6.6665 9.99996L8.77874 11.901C9.13832 12.2246 9.697 12.175 9.994 11.7932L13.3332 7.49996M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39747 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39747 18.3333 9.99984 18.3333Z"
                                            stroke="#80BB01"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_1400_119898">
                                            <rect
                                              width="20"
                                              height="20"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="18"
                                        viewBox="0 0 17 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M12.75 13.25L8.50001 9.00001M8.50001 9.00001L4.25 4.75M8.50001 9.00001L12.75 4.75M8.50001 9.00001L4.25 13.25"
                                          stroke="#FF475D"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    )}
                                    <h4>{"Test " + (index + 1)}</h4>
                                    {index > 0 && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16"
                                        width="16px"
                                        height="16px"
                                      >
                                        <path d="M 8 1 C 5.796875 1 4 2.796875 4 5 L 4 6 L 3.5 6 C 2.675781 6 2 6.675781 2 7.5 L 2 12.5 C 2 13.324219 2.675781 14 3.5 14 L 12.5 14 C 13.324219 14 14 13.324219 14 12.5 L 14 7.5 C 14 6.675781 13.324219 6 12.5 6 L 12 6 L 12 5 C 12 2.796875 10.203125 1 8 1 Z M 8 2 C 9.664063 2 11 3.335938 11 5 L 11 6 L 5 6 L 5 5 C 5 3.335938 6.335938 2 8 2 Z M 3.5 7 L 12.5 7 C 12.78125 7 13 7.21875 13 7.5 L 13 12.5 C 13 12.78125 12.78125 13 12.5 13 L 3.5 13 C 3.21875 13 3 12.78125 3 12.5 L 3 7.5 C 3 7.21875 3.21875 7 3.5 7 Z" />
                                      </svg>
                                    )}
                                  </div>
                                ))
                              ) : (
                                <div
                                  className={
                                    qu?.test_cases
                                      ? "list-item passed"
                                      : "list-item failed"
                                  }
                                >
                                  {qu?.test_cases ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <g clipPath="url(#clip0_1400_119898)">
                                        <path
                                          d="M6.6665 9.99996L8.77874 11.901C9.13832 12.2246 9.697 12.175 9.994 11.7932L13.3332 7.49996M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39747 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39747 18.3333 9.99984 18.3333Z"
                                          stroke="#80BB01"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1400_119898">
                                          <rect
                                            width="20"
                                            height="20"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17"
                                      height="18"
                                      viewBox="0 0 17 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M12.75 13.25L8.50001 9.00001M8.50001 9.00001L4.25 4.75M8.50001 9.00001L12.75 4.75M8.50001 9.00001L4.25 13.25"
                                        stroke="#FF475D"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  )}
                                  <h4>Sample input</h4>
                                </div>
                              )}
                            </div>
                            <div className="col-xs-9 right-code-output">
                              <>
                                <div className="data-section col-xs-12 no-padding">
                                  <h4 className="heading-section">
                                    Sample input
                                  </h4>
                                  <span className="response-section col-xs-12">
                                    {typeof qu?.test_cases?.[0]?.input ==
                                    "object"
                                      ? JSON.stringify(
                                          qu?.test_cases?.[0]?.input,
                                        )
                                      : qu?.test_cases?.[0]?.input}
                                  </span>
                                </div>
                                <div className="data-section col-xs-12 no-padding">
                                  <h4 className="heading-section">
                                    Expected output
                                  </h4>
                                  <span className="response-section col-xs-12">
                                    {/* {qu?.test_cases?.[0]?.expectedOutput} */}
                                    {typeof qu?.test_cases?.[0]
                                      ?.expectedOutput == "object"
                                      ? JSON.stringify(
                                          qu?.test_cases?.[0]?.expectedOutput,
                                        )
                                      : qu?.test_cases?.[0]?.expectedOutput}
                                  </span>
                                </div>
                                <div className="data-section col-xs-12 no-padding">
                                  <h4 className="heading-section">
                                    Actual output
                                  </h4>
                                  <span className="response-section col-xs-12">
                                    {/* {qu?.test_cases?.[0]?.output} */}
                                    {typeof qu?.test_cases?.[0]?.output ==
                                    "object"
                                      ? JSON.stringify(
                                          qu?.test_cases?.[0]?.output,
                                        )
                                      : qu?.test_cases?.[0]?.output}
                                  </span>
                                </div>
                              </>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                    {/* // )} */}
                  </div>
                ))}
              </div>
              {/* // )} */}
            </div>
          )}
        {data?.projectQuestions && data?.projectQuestions?.length > 0 && (
          <div className="main-card col-xs-12 no-padding project">
            <div
              className="header-banner col-xs-12 no-padding"
              onClick={() => {
                if (openSection.includes("project")) {
                  setOpenSection((prev) => prev.filter((d) => d != "project"));
                  setOpenQuestion((prev) => {
                    return { ...prev, project: [] };
                  });
                } else {
                  setOpenSection((prev) => [...prev, "project"]);
                }
              }}
            >
              <div className="col-xs-6 no-padding">
                <h3>Interview</h3>
                <p>{data?.score?.project} score</p>
              </div>
              <div className="col-xs-6 no-padding right">
                <span className="total-questions">
                  <label>Total questions</label>
                  <p>{data?.projectQuestions?.length} questions</p>
                </span>
                <span className="correct-questions">
                  <p>{data?.correctQuestions?.project}</p>
                  <label>Correct</label>
                </span>
                <span className="incorrect-questions">
                  <p>{incorrectQuestion("project")}</p>
                  <label>Incorrect</label>
                </span>
                <span className="skip-questions">
                  <p>{skippedQuestion("project")}</p>
                  <label>Skipped</label>
                </span>
              </div>
            </div>
            {/* {openSection.includes("project") && ( */}
            <div className="project question-section col-xs-12 no-padding">
              <div className="header-row col-xs-12 no-padding">
                <span className="qu">Questions</span>
                {/* <span className="ans">Answers</span> */}
              </div>
              {data?.projectQuestions.map((qu, i) => (
                <div className="col-xs-12 no-padding question-row" key={i}>
                  <div
                    className="col-xs-12 no-padding question"
                    key={i}
                    onClick={() => {
                      if (openQuestion.project.includes(i)) {
                        setOpenQuestion((prev) => {
                          let new_project = prev.project;
                          new_project = new_project.filter((d) => d != i);
                          return { ...prev, project: new_project };
                        });
                      } else {
                        setOpenQuestion((prev) => {
                          let new_project = prev.project;
                          new_project.push(i);
                          return { ...prev, project: new_project };
                        });
                      }
                    }}
                  >
                    <span className="qu">
                      {i + 1}. {qu?.question}
                    </span>
                    {/* <span className="ans">Answers</span> */}
                  </div>

                  {/* {openQuestion?.project?.includes(i) && */}
                  {qu?.answer ? (
                    <div className="col-xs-12 no-padding qu-ans">
                      <span className="label">Answer</span>
                      <p>{qu?.answer}</p>
                    </div>
                  ) : (
                    <div className="col-xs-12 no-padding qu-ans">
                      <span className="label">No answer available</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {/* // )} */}
          </div>
        )}

        {data?.descriptive_questions &&
          data?.descriptive_questions?.length > 0 && (
            <div className="main-card col-xs-12 no-padding project">
              <div className="header-banner col-xs-12 no-padding">
                <div className="col-xs-6 no-padding">
                  <h3>Descriptive Questions</h3>
                  <p>
                    {data?.questionsAttempted?.descriptive_questions?.score}{" "}
                    score
                  </p>
                </div>
                <div className="col-xs-6 no-padding right">
                  <span className="total-questions">
                    <label>Total questions</label>
                    <p>{data?.descriptive_questions?.length} questions</p>
                  </span>
                </div>
              </div>
              <div className="project question-section col-xs-12 no-padding">
                <div className="header-row col-xs-12 no-padding">
                  <span className="qu">Questions and Answers</span>
                </div>
                {data?.descriptive_questions?.map((question, index) => {
                  const candidateAnswer = getCandidateAnswer(question._id);

                  return (
                    <div
                      className="col-xs-12 no-padding question-row"
                      key={index}
                    >
                      <div className="col-xs-12 no-padding question">
                        <div className="qu">
                          <strong>
                            {index + 1}. {question.question}
                          </strong>
                          {/* <div className="question-meta">
                            <span className="badge bg-primary me-2">
                              Level: {question.level}
                            </span>
                            <span className="badge bg-secondary me-2">
                              Skill: {question.skill}
                            </span>
                            <span className="badge bg-info">
                              Type: {question.type_of_question}
                            </span>
                          </div> */}
                        </div>
                      </div>

                      <div className="col-xs-12 no-padding question">
                        {/* <div className="answer-section"> */}
                        {/* <h4>Candidate's Answer:</h4> */}
                        {candidateAnswer ? (
                          <div>
                            <p className="answer-text pl-4">
                              {candidateAnswer.answer}
                            </p>
                            {/* <small className="text-muted">
                                Answered at:{" "}
                                {new Date(
                                  candidateAnswer.answered_at
                                ).toLocaleString()}
                              </small> */}
                          </div>
                        ) : (
                          <p className="no-answer pl-4">No answer provided</p>
                        )}
                        {/* </div> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
      </main>
    </div>
  );
  // );
}

export default AssessmentEvalReport;
