import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CandidatePageContext } from "../../../../lib/context";
import InitialsLogo from "../../../all/InitialsLogo";
import { ScaleLoader } from "react-spinners";
import moment from "moment";
import ProtectedComponent from "../../../../layouts/ProtectedComponent";
const core = require("../../../../lib/core");
const colorArray = [
  "#61A3BA",
  "#A9A9A9",
  "#0C356A",
  "#04364A",
  "#99A98F",
  "#245953",
  "#8785A2",
  "#8D7B68",
];

const InterviewScheduledCard = ({
  dataList,
  setEdtitFormdetails,
  setShowScheduleForm,
  setIsEditing,
  setEditMeetingId,
  matchProfileId,
  checkResource = false,
}) => {
  const { setShowModal } = useContext(CandidatePageContext);
  const nav = useNavigate();
  const candidate = useSelector((state) => state?.jobCandidateMatch?.profile);
  const jobId = useSelector((state) => state?.JobExpandSlice?.profile?.id);

  const jobsd = useSelector((state) => console.log(state));
  const [upcomingGroupedMeetings, setGroupedMeetings] = useState({});
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [listLoading, setListLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Upcoming events");

  const [OldEvents, setOldEvents] = useState({});
  const [fetched, setFetched] = useState(false);
  const getOldEvents = async () => {
    setListLoading(true);
    try {
      const requestBody = {
        match_profile_id: matchProfileId,
        status: "completed",
      };
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.GET_INTERVIEWS,
        1,
        requestBody,
      );

      if (json?.data && json?.code == 200) {
        console.log("Past events", json?.data);
        const groupedPastEvents = json.data.reduce((acc, event) => {
          const date = moment(event.start_time).format("YYYY-MM-DD");
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(event);
          return acc;
        }, {});
        setOldEvents(groupedPastEvents);
        setFetched(true);
      } else {
        console.log("Past events ERR", json);
      }
    } catch (error) {
      console.log("Unable to get Old events");
    } finally {
      setListLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTab === "Past events" && !fetched) {
      getOldEvents();
    }
  }, [selectedTab, fetched]);

  function stringToColor(string) {
    if (!colorArray || colorArray.length === 0) {
      return null; // Handle the case when the colorArray is empty or undefined
    }

    let hash = 0;
    for (let i = 0; i < string?.length; i += 1) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    const index = Math.abs(hash) % colorArray.length; // Calculate the index based on the hash
    return colorArray[index];
  }

  const toggleDropdown = (date, index) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [date]: prevState[date] === index ? null : index,
    }));
  };
  const sortedDataList = [...dataList].sort((a, b) => {
    if (!a.start_time) return 1;
    if (!b.start_time) return -1;
    return moment(a.start_time).diff(moment(b.start_time));
  });

  useEffect(() => {
    setListLoading(true);
    if (dataList && dataList.length > 0) {
      const groupMeetings = async () => {
        const grouped = dataList.reduce((acc, meeting) => {
          const date = moment(meeting.start_time).format("YYYY-MM-DD");
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(meeting);
          return acc;
        }, {});
        const upcomingListEvents = Object.entries(grouped).reduce(
          (acc, [date, meetings]) => {
            if (moment(date).isSameOrAfter(now, "day")) {
              acc[date] = meetings;
            }
            return acc;
          },
          {},
        );

        setGroupedMeetings(upcomingListEvents);

        setListLoading(false);
      };

      groupMeetings();
    } else {
      setGroupedMeetings({});
      setListLoading(false);
    }
  }, [dataList]);

  const [isCopied, setisCopied] = useState(false);
  const now = moment();

  const isToday = (date) => {
    return moment(date).isSame(moment(), "day");
  };
  // const upcomingMeetings = Object.entries(groupedMeetings)
  //   .filter(([date, _]) => moment(date).isSameOrAfter(now, "day"))
  //   .reduce((acc, [date, meetings]) => {
  //     acc[date] = meetings;
  //     return acc;
  //   }, {});

  // const pastMeetings = Object.entries(groupedMeetings)
  //   .filter(([date, _]) => moment(date).isBefore(now, "day"))
  //   .sort(([dateA], [dateB]) => moment(dateB).diff(moment(dateA))) // Note : The recent completed event is at top and old ones will be at the bottom
  //   .reduce((acc, [date, meetings]) => {
  //     acc[date] = meetings;
  //     return acc;
  //   }, {});
  const formatTimeTo12Hour = (timeString) => {
    const date = new Date(timeString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes} ${ampm}`;
  };
  const handleEditMeeting = (Day, Index) => {
    const MeetingDetails = upcomingGroupedMeetings[Day][Index];
    setEdtitFormdetails({
      title: MeetingDetails?.title || "",
      description: MeetingDetails?.description || "",
      link: MeetingDetails?.meeting_link || "",
      InterviewerEmails: MeetingDetails?.guest || [],
      time: formatTimeTo12Hour(MeetingDetails?.start_time) || "",
      endtime: formatTimeTo12Hour(MeetingDetails?.end_time) || "",
      interviewStage: MeetingDetails?.stage,
      date: new Date(Day),
      assistOption:
        MeetingDetails?.assistant_required == 1 ? "botAssist" : "noteTaker",
    });
    setIsEditing(true);
    setEditMeetingId(upcomingGroupedMeetings[Day][Index]?._id);
    setShowScheduleForm(true);
  };
  // if (Object.keys(groupedMeetings).length === 0)
  //   return (
  //     <div className="w-full mb-4 last:mb-0 flex justify-between">
  //       <div className="w-full h-96 p-8 border-none flex items-center gap-4 flex-col justify-center">
  //         <svg
  //           xmlns="http://www.w3.org/2000/svg"
  //           fill="none"
  //           viewBox="0 0 24 24"
  //           stroke="currentColor"
  //           className="w-8 h-8 mr-2"
  //         >
  //           <path
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //             strokeWidth="1.5"
  //             d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
  //           />
  //         </svg>
  //         <h1>No Inteviews Scheduled </h1>
  //       </div>
  //     </div>
  //   );

  return (
    <>
      <div className=" bg-customColor text-base font-normal py-px px-px my-4 flex items-center justify-center gap-4 w-fit rounded-lg">
        <button
          className={`px-4 py-2 w-64  rounded-md ${selectedTab === "Upcoming events" ? "bg-white text-blue" : "text-gray-800"}`}
          onClick={() => setSelectedTab("Upcoming events")}
        >
          Upcoming Events
        </button>
        <button
          className={`px-4 py-2 w-64  rounded-md ${selectedTab === "Past events" ? "bg-white text-blue" : "text-gray-800"}`}
          onClick={() => setSelectedTab("Past events")}
        >
          Past Events
        </button>
      </div>
      <div className="overflow-x-auto">
        {listLoading ? (
          <div className="flex w-full justify-center items-center h-32">
            <ScaleLoader height={30} color="#1569EB" loading={true} />
          </div>
        ) : (
          <>
            {Object.keys(
              selectedTab === "Upcoming events"
                ? upcomingGroupedMeetings
                : OldEvents,
            ).length > 0 ? (
              Object.entries(
                selectedTab === "Upcoming events"
                  ? upcomingGroupedMeetings
                  : OldEvents,
              ).map(([date, meetings]) => (
                <div key={date} className="py-4 ">
                  <h2 className="text-md font-semibold mb-4">
                    {moment(date).format("dddd, MMMM D, YYYY")}
                  </h2>
                  <div className="">
                    <div className="min-w-full shadow-md w-full">
                      <div className="bg-gray-400 flex rounded-t-lg">
                        <div className="py-2 w-[30%] px-4 text-gray-800 not-italic font-normal text-sm ">
                          Title
                        </div>
                        <div className="py-2 px-4 w-[20%] text-gray-800 not-italic font-normal text-sm ">
                          Organizer
                        </div>
                        <div className="py-2 px-4 w-[20%] text-gray-800 not-italic font-normal text-sm ">
                          Candidate
                        </div>
                        <div className="py-2 px-4 w-[30%] text-gray-800 not-italic font-normal text-sm ">
                          Actions
                        </div>
                      </div>
                      <div className="flex flex-col">
                        {meetings.map((meeting, index) => (
                          <React.Fragment key={index}>
                            <div
                              className={`
                              bg-white 
                              flex flex-row 
                              ${index === meetings.length - 1 ? "rounded-b-lg" : ""}
                              ${openDropdowns[date] === index ? "bg-gray-400 shadow-sm border-l-4 border-l-indigo-500 rounded" : ""}
                            `}
                            >
                              <div className="py-4 px-4 w-[30%]">
                                <div className="text-base not-italic  font-medium text-gray-800 ">
                                  {meeting?.title}
                                </div>
                                <span className="text-sm not-italic font-normal  text-gray-700  mt-2">
                                  {meeting?.start_time ? (
                                    <>
                                      <span className="text-xs">
                                        {moment(meeting.start_time).format(
                                          "LL",
                                        )}
                                      </span>
                                      <p></p>
                                      {moment(meeting.start_time).format(
                                        "h:mm A",
                                      )}{" "}
                                      -{" "}
                                      {moment(meeting.end_time).format(
                                        "h:mm A",
                                      )}
                                      <div className="mt-4 w-fit font-medium flex flex-col gap-2">
                                        {meeting?.status === "active" ? (
                                          <>
                                            <div className=" w-fit  flex justify-center items-center gap-1 px-2 py-1 rounded-md bg-yellow-200">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 24 24"
                                              >
                                                <path
                                                  fill="currentColor"
                                                  d="M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-2q-2.5 0-4.25-1.75T6 12t1.75-4.25T12 6t4.25 1.75T18 12t-1.75 4.25T12 18m0-2q1.65 0 2.825-1.175T16 12t-1.175-2.825T12 8T9.175 9.175T8 12t1.175 2.825T12 16m0-2q-.825 0-1.412-.587T10 12t.588-1.412T12 10t1.413.588T14 12t-.587 1.413T12 14"
                                                />
                                              </svg>
                                              <p className="text-xs text-yellow-800 m-0">
                                                Active
                                              </p>
                                            </div>
                                          </>
                                        ) : meeting?.status === "completed" ? (
                                          <>
                                            <div className=" w-fit  flex items-center gap-1 justify-center px-2 py-1 rounded-md bg-green-200">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 24 24"
                                              >
                                                <g fill="none">
                                                  <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeWidth="2"
                                                    d="m2 14l3.233 2.425a1 1 0 0 0 1.374-.167L15 6"
                                                  />
                                                  <path
                                                    fill="currentColor"
                                                    fillRule="evenodd"
                                                    d="m9.874 15.78l1.729 1.383a2 2 0 0 0 2.797-.295l8.374-10.235a1 1 0 0 0-1.548-1.266L12.852 15.6l-1.711-1.369z"
                                                    clipRule="evenodd"
                                                  />
                                                </g>
                                              </svg>
                                              <p className="text-xs text-green-900 m-0">
                                                Completed
                                              </p>
                                            </div>
                                          </>
                                        ) : meeting?.status === "expired" ? (
                                          <>
                                            <div className="  w-fit flex items-center gap-1 justify-center px-2 py-1 rounded-md bg-gray-400">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fill="currentColor"
                                                  fillRule="evenodd"
                                                  d="M8.175.002a8 8 0 1 0 2.309 15.603a.75.75 0 0 0-.466-1.426a6.5 6.5 0 1 1 3.996-8.646a.75.75 0 0 0 1.388-.569A8 8 0 0 0 8.175.002M8.75 3.75a.75.75 0 0 0-1.5 0v3.94L5.216 9.723a.75.75 0 1 0 1.06 1.06L8.53 8.53l.22-.22zM15 15a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-.25-6.25a.75.75 0 0 0-1.5 0v3.5a.75.75 0 0 0 1.5 0z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                              <p className="text-xs text-gray-800 m-0">
                                                Expired
                                              </p>
                                            </div>
                                          </>
                                        ) : (
                                          <div className=" w-fit  flex items-center gap-1 justify-center px-2 py-1 rounded-md bg-red-200">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="1em"
                                              height="1em"
                                              className="text-red-800"
                                              viewBox="0 0 24 24"
                                            >
                                              <g
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                              >
                                                <path
                                                  strokeDasharray="64"
                                                  strokeDashoffset="64"
                                                  d="M5.64 5.64c3.51 -3.51 9.21 -3.51 12.73 0c3.51 3.51 3.51 9.21 0 12.73c-3.51 3.51 -9.21 3.51 -12.73 0c-3.51 -3.51 -3.51 -9.21 -0 -12.73Z"
                                                >
                                                  <animate
                                                    fill="freeze"
                                                    attributeName="stroke-dashoffset"
                                                    dur="0.6s"
                                                    values="64;0"
                                                  />
                                                </path>
                                                <path
                                                  strokeDasharray="20"
                                                  strokeDashoffset="20"
                                                  d="M6 6l12 12"
                                                >
                                                  <animate
                                                    fill="freeze"
                                                    attributeName="stroke-dashoffset"
                                                    begin="0.6s"
                                                    dur="0.2s"
                                                    values="20;0"
                                                  />
                                                </path>
                                              </g>
                                            </svg>
                                            <p className="text-xs text-red-800 m-0">
                                              Cancelled
                                            </p>
                                          </div>
                                        )}
                                        <span>
                                          {meeting?.stage && (
                                            <div className=" w-fit  flex items-center  justify-center px-2 py-1 rounded-md bg-blue-200">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                className="w-4 h-4 mr-2"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="1.5"
                                                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                                                />
                                              </svg>
                                              <p className="m-0 text-xs">
                                                {meeting.stage}
                                              </p>
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </div>
                              <div className="py-4 px-4 w-[20%]">
                                <div className="text-base not-italic break-all overflow-hidden font-medium text-gray-800 ">
                                  {meeting?.organizer?.email}
                                </div>
                                {/* <span className="text-sm not-italic font-normal  text-gray-700  mt-2">
                          Software Engineer
                        </span> */}
                              </div>
                              <div className="text-base font-medium  py-4 px-4 w-[20%] break-all overflow-hidden">
                                {
                                  meeting?.matchprofile?.[0]?.applicant
                                    ?.first_name
                                }{" "}
                                {
                                  meeting?.matchprofile?.[0]?.applicant
                                    ?.last_name
                                }
                              </div>
                              <div className="py-4 px-4 w-[30%]">
                                <div className="flex items-center gap-2 justify-start flex-wrap">
                                  <button
                                    onClick={() => toggleDropdown(date, index)}
                                    className="btn-sec shadow-sm btn-sm p-4 rounded-md mr-2"
                                  >
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M4.25 4A2.25 2.25 0 0 0 2 6.25v2.5A2.25 2.25 0 0 0 4.25 11h2.5A2.25 2.25 0 0 0 9 8.75v-2.5A2.25 2.25 0 0 0 6.75 4zM3.5 6.25a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 .75.75v2.5a.75.75 0 0 1-.75.75h-2.5a.75.75 0 0 1-.75-.75zM11.25 5a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5zm0 3a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5zm-7 5A2.25 2.25 0 0 0 2 15.25v2.5A2.25 2.25 0 0 0 4.25 20h2.5A2.25 2.25 0 0 0 9 17.75v-2.5A2.25 2.25 0 0 0 6.75 13zm-.75 2.25a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 .75.75v2.5a.75.75 0 0 1-.75.75h-2.5a.75.75 0 0 1-.75-.75zM11.25 14a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5zm0 3a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 0-1.5z"
                                        />
                                      </svg>
                                    </span>
                                    Details
                                  </button>
                                  <button
                                    className={`btn-primary shadow-sm btn-sm p-4 rounded-md ${
                                      selectedTab === "Past events"
                                        ? "opacity-25 cursor-not-allowed display-none  border-none text-gray-900 bg-gray-400"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      if (selectedTab !== "Past events") {
                                        if (meeting?.assistant_required) {
                                          nav(
                                            "/interview/" +
                                              meeting?._id +
                                              "?link=" +
                                              meeting?.meeting_link +
                                              "&" +
                                              candidate?.matchProfile?._id +
                                              "&" +
                                              jobId,
                                            {
                                              state: {
                                                jobID: jobId,
                                                candID:
                                                  candidate?.matchProfile?._id,
                                              },
                                            },
                                          );
                                        }

                                        // Open the meeting link in a new tab
                                        window.open(
                                          meeting?.meeting_link,
                                          "_blank",
                                        );
                                      }
                                    }}
                                    disabled={selectedTab === "Past events"}
                                  >
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 16 16"
                                      height="1.5em"
                                      width="2em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z"
                                      ></path>
                                    </svg>
                                    {selectedTab !== "Past events"
                                      ? "Join"
                                      : "Expired"}
                                  </button>

                                  <ProtectedComponent
                                    showModal={true}
                                    code={
                                      checkResource
                                        ? "Interview_create"
                                        : "no_code"
                                    }
                                  >
                                    <button
                                      className={`btn-sec shadow-sm btn-sm p-4 rounded-md border-2 border-gray-400  ${selectedTab === "Past events" ? "opacity-50 cursor-not-allowed hover:border-gray-400 hover:text-gray-900" : ""}`}
                                      onClick={() =>
                                        handleEditMeeting(date, index)
                                      }
                                    >
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="15"
                                          height="15"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M3.995 17.207V19.5a.5.5 0 0 0 .5.5h2.298a.5.5 0 0 0 .353-.146l9.448-9.448l-3-3l-9.452 9.448a.5.5 0 0 0-.147.353m10.837-11.04l3 3l1.46-1.46a1 1 0 0 0 0-1.414l-1.585-1.586a1 1 0 0 0-1.414 0z"
                                          />
                                        </svg>
                                      </span>
                                      Edit
                                    </button>
                                  </ProtectedComponent>
                                </div>
                              </div>
                            </div>
                            {openDropdowns[date] === index && (
                              <div className="bg-gray-200 rounded-b-lg mb-2 ">
                                <div colSpan="6" className="px-4 py-2 ">
                                  <div className="p-2  grid grid-cols-2">
                                    <div className="w-[50%] flex-col flex-wrap">
                                      <h3 className="flex items-center gap-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="17"
                                          viewBox="0 0 20 17"
                                          fill="none"
                                        >
                                          <path
                                            d="M19 15.9999C19 14.2583 17.3304 12.7767 15 12.2275M13 16C13 13.7909 10.3137 12 7 12C3.68629 12 1 13.7909 1 16M13 9C15.2091 9 17 7.20914 17 5C17 2.79086 15.2091 1 13 1M7 9C4.79086 9 3 7.20914 3 5C3 2.79086 4.79086 1 7 1C9.20914 1 11 2.79086 11 5C11 7.20914 9.20914 9 7 9Z"
                                            stroke="#252E4B"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        Interviewer
                                      </h3>
                                      {meeting.guest.map(
                                        (interviewer, index) => (
                                          <div
                                            key={index}
                                            className="flex gap-2 mb-4 mt-4 items-center"
                                          >
                                            <InitialsLogo
                                              width="20px"
                                              height="20px"
                                              str={interviewer.split("@")[0]}
                                            />
                                            <div className="flex flex-col">
                                              <h className="text-base not-italic font-medium text-gray-800 ">
                                                {interviewer}
                                              </h>
                                              <span className="text-sm not-italic font-normal text-gray-700">
                                                {/* {interviewer.organization} */}
                                              </span>
                                            </div>
                                          </div>
                                        ),
                                      )}
                                      {meeting.interviewer.map(
                                        (interviewer, index) => (
                                          <div
                                            key={index}
                                            className="flex gap-2 mb-4 mt-4 items-center"
                                          >
                                            <InitialsLogo
                                              width="20px"
                                              height="20px"
                                              str={interviewer.name}
                                            />
                                            <div className="flex flex-col">
                                              <h className="text-base not-italic font-medium text-gray-800 ">
                                                {interviewer?.name}
                                              </h>
                                              <span className="text-sm not-italic font-normal text-gray-700">
                                                {interviewer.email}
                                              </span>
                                            </div>
                                          </div>
                                        ),
                                      )}
                                    </div>
                                    <div className="mt-0 w-full">
                                      <h1 className="flex items-center gap-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M2 8C2 5.79086 3.79086 4 6 4H13C15.2091 4 17 5.79086 17 8V16C17 18.2091 15.2091 20 13 20H6C3.79086 20 2 18.2091 2 16V8Z"
                                            stroke="#252E4B"
                                            strokeWidth="1.5"
                                          />
                                          <path
                                            d="M17 9.33333L18.5409 7.68968C19.781 6.36697 22 7.24449 22 9.05756V14.9424C22 16.7555 19.781 17.633 18.5409 16.3103L17 14.6667V9.33333Z"
                                            stroke="#252E4B"
                                            strokeWidth="1.5"
                                          />
                                          <path
                                            d="M13 10C13 11.1046 12.1046 12 11 12C9.89543 12 9 11.1046 9 10C9 8.89543 9.89543 8 11 8C12.1046 8 13 8.89543 13 10Z"
                                            stroke="#252E4B"
                                            strokeWidth="1.5"
                                          />
                                        </svg>{" "}
                                        Meeting link
                                        <span className="mx-4">
                                          {isCopied ? (
                                            <div className="text-green-900 bg-green-200 px-2 py-1 rounded flex items-center gap-2">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 24 24"
                                              >
                                                <g
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="2"
                                                >
                                                  <path
                                                    strokeDasharray="72"
                                                    strokeDashoffset="72"
                                                    d="M12 3h7v18h-14v-18h7Z"
                                                  >
                                                    <animate
                                                      fill="freeze"
                                                      attributeName="stroke-dashoffset"
                                                      dur="0.6s"
                                                      values="72;0"
                                                    />
                                                  </path>
                                                  <path
                                                    strokeDasharray="12"
                                                    strokeDashoffset="12"
                                                    strokeWidth="1"
                                                    d="M14.5 3.5v3h-5v-3"
                                                  >
                                                    <animate
                                                      fill="freeze"
                                                      attributeName="stroke-dashoffset"
                                                      begin="0.7s"
                                                      dur="0.2s"
                                                      values="12;0"
                                                    />
                                                  </path>
                                                  <path
                                                    strokeDasharray="12"
                                                    strokeDashoffset="12"
                                                    d="M9 13l2 2l4 -4"
                                                  >
                                                    <animate
                                                      fill="freeze"
                                                      attributeName="stroke-dashoffset"
                                                      begin="0.9s"
                                                      dur="0.2s"
                                                      values="12;0"
                                                    />
                                                  </path>
                                                </g>
                                              </svg>
                                              <p className="m-0">Copied!</p>
                                            </div>
                                          ) : (
                                            <svg
                                              className="cursor-pointer text-gray-700 hover:text-gray-900"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="18"
                                              viewBox="0 0 22 22"
                                              fill="none"
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  meeting.meeting_link,
                                                );
                                                setisCopied(true);
                                                setTimeout(
                                                  () => setisCopied(false),
                                                  2000,
                                                );
                                              }}
                                            >
                                              <path
                                                d="M7 7V5C7 2.79086 8.79086 1 11 1L17 1C19.2091 1 21 2.79086 21 5V11C21 13.2091 19.2091 15 17 15H15M7 7H5C2.79086 7 1 8.79086 1 11V17C1 19.2091 2.79086 21 5 21H11C13.2091 21 15 19.2091 15 17V15M7 7H11C13.2091 7 15 8.79086 15 11V15"
                                                stroke="#252E4B"
                                                strokeWidth="1.5"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          )}
                                        </span>
                                      </h1>
                                      {meeting?.meeting_link && (
                                        <p className="mt-2 flex items-center gap-4">
                                          {" "}
                                          <a
                                            href={meeting.url}
                                            className="text-blue-500 w-full break-all"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {meeting?.meeting_link}
                                          </a>
                                        </p>
                                      )}
                                    </div>
                                    <div className="mt-8">
                                      <h1 className="flex items-center gap-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="22"
                                          viewBox="0 0 20 22"
                                          fill="none"
                                        >
                                          <path
                                            d="M19 8V17C19 19.2091 17.2091 21 15 21H5C2.79086 21 1 19.2091 1 17V5C1 2.79086 2.79086 1 5 1H12M19 8L12 1M19 8H16C13.7909 8 12 6.20914 12 4V1"
                                            stroke="#252E4B"
                                            strokeWidth="1.5"
                                            strokeLinejoin="round"
                                          />
                                        </svg>{" "}
                                        Description
                                      </h1>
                                      <p className="mt-2 flex items-center text-justify gap-4">
                                        {" "}
                                        {meeting?.description ||
                                          "No description"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full h-96 p-8 border-none flex items-center gap-4 flex-col justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-8 h-8 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                  />
                </svg>
                <h1>No {selectedTab} </h1>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default InterviewScheduledCard;
