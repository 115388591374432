import React, { useState, useRef, useEffect } from "react";
import Modal from "../../modal/Modal";
import { ScaleLoader } from "react-spinners";
import InputBox from "../../../layouts/InputBox";
import { useSelector } from "react-redux";

const core = require("../../../lib/core");

const validateLink = (link, type) => {
  // Basic URL validation
  try {
    new URL(link);
  } catch {
    return {
      isValid: false,
      message: "Please enter a valid URL (e.g., https://example.com)",
    };
  }

  // Define patterns for different platforms
  const patterns = {
    Facebook: {
      pattern: /^https?:\/\/(www\.)?(facebook|fb)\.com/i,
      example: "https://facebook.com/username or https://fb.com/username",
    },
    Instagram: {
      pattern: /^https?:\/\/(www\.)?instagram\.com/i,
      example: "https://instagram.com/username",
    },
    Github: {
      pattern: /^https?:\/\/(www\.)?github\.com/i,
      example: "https://github.com/username",
    },
    "Coding platform": {
      pattern:
        /^https?:\/\/(www\.)?(leetcode\.com|hackerrank\.com|codechef\.com|codeforces\.com|kaggle\.com)/i,
      example:
        "URLs from LeetCode, HackerRank, CodeChef, CodeForces, or Kaggle",
    },
    X: {
      pattern: /^https?:\/\/(www\.)?(twitter\.com|x\.com)/i,
      example: "https://twitter.com/username or https://x.com/username",
    },
    Portfolio: {
      pattern: /^https?:\/\//i, // Accept any valid URL for portfolio
      example: "Any valid website URL",
    },
    Others: {
      pattern: /^https?:\/\//i, // Accept any valid URL for others
      example: "Any valid website URL",
    },
  };

  // If type is specified and not "Others" or "Portfolio", validate against pattern
  if (type && type !== "Others" && type !== "Portfolio") {
    const platformPattern = patterns[type];
    if (!platformPattern.pattern.test(link)) {
      return {
        isValid: false,
        message: `This doesn't look like a valid ${type} link.`,
      };
    }
  }

  return {
    isValid: true,
    message: "Link is valid",
  };
};

const CustomDropdown = ({
  selectedValue,
  setSelectedValue,
  placeholder = "Select option",
  options = [],
  allowCustom = true,
  customOptionPlaceholder = "Add custom option",
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [customValue, setCustomValue] = useState("");
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleCustomSubmit = (e) => {
    e.preventDefault();
    if (customValue.trim()) {
      setSelectedValue(customValue.trim());
      setCustomValue("");
      setIsOpen(false);
    }
  };

  const handleOptionClick = (option) => {
    setSelectedValue(option);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={wrapperRef}>
      <div
        className={`p-1 h-[32px] border border-gray-400 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[14px] placeholder:font-normal active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[#A9A9AC] ${className}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          className="flex items-center justify-between"
          data-tooltip-id="tooltip"
          data-tooltip-content={selectedValue ? selectedValue : ""}
        >
          <span
            className={`w-full px-2 ${selectedValue ? "text-black" : "text-[#A9A9AC]"}`}
          >
            {selectedValue || placeholder}
          </span>
          <svg
            className="w-5 h-5 text-gray-700"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>

      {isOpen && (
        <div className="absolute z-[100] w-full mt-1 bg-white border rounded-md shadow-lg">
          {allowCustom && (
            <form onSubmit={handleCustomSubmit} className="p-2 border-b">
              <input
                type="text"
                value={customValue}
                onChange={(e) => setCustomValue(e.target.value)}
                placeholder={customOptionPlaceholder}
                className="w-full p-2 border placeholder:text-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </form>
          )}
          <ul className="max-h-60 overflow-auto p-2">
            {options.length > 0 ? (
              options.map((option, index) => (
                <li
                  key={index}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-300 rounded truncate"
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </li>
              ))
            ) : (
              <li className="px-4 py-2 text-gray-500 italic">
                No options available
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
const GetLinkIcon = (type) => {
  switch (type) {
    case "Facebook":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2em"
          height="2em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M15.725 22v-7.745h2.6l.389-3.018h-2.99V9.31c0-.874.243-1.47 1.497-1.47h1.598v-2.7a21 21 0 0 0-2.33-.12c-2.304 0-3.881 1.407-3.881 3.99v2.227H10v3.018h2.607V22H3.104C2.494 22 2 21.506 2 20.896V3.104C2 2.494 2.494 2 3.104 2h17.792C21.506 2 22 2.494 22 3.104v17.792c0 .61-.494 1.104-1.104 1.104z"
          />
        </svg>
      );
    case "X":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.75em"
          height="1.75em"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="m3 21l7.548-7.548M21 3l-7.548 7.548m0 0L8 3H3l7.548 10.452m2.904-2.904L21 21h-5l-5.452-7.548"
            color="currentColor"
          />
        </svg>
      );
    case "Instagram":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2em"
          height="2em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M12.001 9a3 3 0 1 0 0 6a3 3 0 0 0 0-6m0-2a5 5 0 1 1 0 10a5 5 0 0 1 0-10m6.5-.25a1.25 1.25 0 0 1-2.5 0a1.25 1.25 0 0 1 2.5 0M12.001 4c-2.474 0-2.878.007-4.029.058c-.784.037-1.31.142-1.798.332a2.9 2.9 0 0 0-1.08.703a2.9 2.9 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.007 9.075 4 9.461 4 12c0 2.475.007 2.878.058 4.029c.037.783.142 1.31.331 1.797c.17.435.37.748.702 1.08c.337.336.65.537 1.08.703c.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.475 0 2.878-.007 4.029-.058c.782-.037 1.308-.142 1.797-.331a2.9 2.9 0 0 0 1.08-.703c.337-.336.538-.649.704-1.08c.19-.492.296-1.018.332-1.8c.052-1.103.058-1.49.058-4.028c0-2.474-.007-2.878-.058-4.029c-.037-.782-.143-1.31-.332-1.798a2.9 2.9 0 0 0-.703-1.08a2.9 2.9 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.926 4.006 14.54 4 12 4m0-2c2.717 0 3.056.01 4.123.06c1.064.05 1.79.217 2.427.465c.66.254 1.216.598 1.772 1.153a4.9 4.9 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428c.047 1.066.06 1.405.06 4.122s-.01 3.056-.06 4.122s-.218 1.79-.465 2.428a4.9 4.9 0 0 1-1.153 1.772a4.9 4.9 0 0 1-1.772 1.153c-.637.247-1.363.415-2.427.465c-1.067.047-1.406.06-4.123.06s-3.056-.01-4.123-.06c-1.064-.05-1.789-.218-2.427-.465a4.9 4.9 0 0 1-1.772-1.153a4.9 4.9 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.012 15.056 2 14.717 2 12s.01-3.056.06-4.122s.217-1.79.465-2.428a4.9 4.9 0 0 1 1.153-1.772A4.9 4.9 0 0 1 5.45 2.525c.637-.248 1.362-.415 2.427-.465C8.945 2.013 9.284 2 12.001 2"
          />
        </svg>
      );
    case "Github":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2em"
          height="2em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12.026 2c-5.509 0-9.974 4.465-9.974 9.974c0 4.406 2.857 8.145 6.821 9.465c.499.09.679-.217.679-.481c0-.237-.008-.865-.011-1.696c-2.775.602-3.361-1.338-3.361-1.338c-.452-1.152-1.107-1.459-1.107-1.459c-.905-.619.069-.605.069-.605c1.002.07 1.527 1.028 1.527 1.028c.89 1.524 2.336 1.084 2.902.829c.091-.645.351-1.085.635-1.334c-2.214-.251-4.542-1.107-4.542-4.93c0-1.087.389-1.979 1.024-2.675c-.101-.253-.446-1.268.099-2.64c0 0 .837-.269 2.742 1.021a9.6 9.6 0 0 1 2.496-.336a9.6 9.6 0 0 1 2.496.336c1.906-1.291 2.742-1.021 2.742-1.021c.545 1.372.203 2.387.099 2.64c.64.696 1.024 1.587 1.024 2.675c0 3.833-2.33 4.675-4.552 4.922c.355.308.675.916.675 1.846c0 1.334-.012 2.41-.012 2.737c0 .267.178.577.687.479C19.146 20.115 22 16.379 22 11.974C22 6.465 17.535 2 12.026 2"
            clipRule="evenodd"
          />
        </svg>
      );
    case "Coding platform":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2em"
          height="2em"
          viewBox="0 0 32 32"
        >
          <path
            fill="currentColor"
            d="M4.014 14.976a2.5 2.5 0 0 0 1.567-.518a2.38 2.38 0 0 0 .805-1.358a15.3 15.3 0 0 0 .214-2.944q.012-2.085.075-2.747a5.2 5.2 0 0 1 .418-1.686a3 3 0 0 1 .755-1.018A3.05 3.05 0 0 1 9 4.125A6.8 6.8 0 0 1 10.544 4h.7v1.96h-.387a2.34 2.34 0 0 0-1.723.468a3.4 3.4 0 0 0-.425 2.092a36 36 0 0 1-.137 4.133a4.7 4.7 0 0 1-.768 2.06A4.6 4.6 0 0 1 6.1 16a3.8 3.8 0 0 1 1.992 1.754a8.9 8.9 0 0 1 .618 3.865q0 2.435.05 2.9a1.76 1.76 0 0 0 .504 1.181a2.64 2.64 0 0 0 1.592.337h.387V28h-.7a5.7 5.7 0 0 1-1.773-.2a2.97 2.97 0 0 1-1.324-.93a3.35 3.35 0 0 1-.681-1.63a24 24 0 0 1-.165-3.234a16.5 16.5 0 0 0-.214-3.106a2.4 2.4 0 0 0-.805-1.361a2.5 2.5 0 0 0-1.567-.524Zm23.972 2.035a2.5 2.5 0 0 0-1.567.524a2.4 2.4 0 0 0-.805 1.361a16.5 16.5 0 0 0-.212 3.109a24 24 0 0 1-.169 3.234a3.35 3.35 0 0 1-.681 1.63a2.97 2.97 0 0 1-1.324.93a5.7 5.7 0 0 1-1.773.2h-.7V26.04h.387a2.64 2.64 0 0 0 1.592-.337a1.76 1.76 0 0 0 .506-1.186q.05-.462.05-2.9a8.9 8.9 0 0 1 .618-3.865A3.8 3.8 0 0 1 25.9 16a4.6 4.6 0 0 1-1.7-1.286a4.7 4.7 0 0 1-.768-2.06a36 36 0 0 1-.137-4.133a3.4 3.4 0 0 0-.425-2.092a2.34 2.34 0 0 0-1.723-.468h-.387V4h.7a6.8 6.8 0 0 1 1.54.125a3.05 3.05 0 0 1 1.149.581a3 3 0 0 1 .755 1.018a5.2 5.2 0 0 1 .418 1.686q.062.662.075 2.747a15.3 15.3 0 0 0 .212 2.947a2.38 2.38 0 0 0 .805 1.355a2.5 2.5 0 0 0 1.567.518Z"
          />
        </svg>
      );
    case "Portfolio":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2em"
          height="2em"
          viewBox="0 0 20 20"
        >
          <path
            fill="currentColor"
            d="M10 2a4 4 0 1 0 0 8a4 4 0 0 0 0-8m-4.991 9A2 2 0 0 0 3 13c0 1.691.833 2.966 2.135 3.797c1.086.692 2.493 1.075 4.026 1.176A3 3 0 0 1 9 17v-4c0-.768.289-1.47.764-2zM10 13a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2zm6.5 3h-4a.5.5 0 1 0 0 1h4a.5.5 0 1 0 0-1m-4-3a.5.5 0 1 0 0 1h4a.5.5 0 1 0 0-1z"
          />
        </svg>
      );
    case "Docs":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2em"
          height="2em"
          viewBox="0 0 24 24"
        >
          <g
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          >
            <path d="M14.186 2.753v3.596c0 .487.194.955.54 1.3a1.85 1.85 0 0 0 1.306.539h4.125" />
            <path d="M20.25 8.568v8.568a4.25 4.25 0 0 1-1.362 2.97a4.28 4.28 0 0 1-3.072 1.14h-7.59a4.3 4.3 0 0 1-3.1-1.124a4.26 4.26 0 0 1-1.376-2.986V6.862a4.25 4.25 0 0 1 1.362-2.97a4.28 4.28 0 0 1 3.072-1.14h5.714a3.5 3.5 0 0 1 2.361.905l2.96 2.722a2.97 2.97 0 0 1 1.031 2.189M7.647 7.647h3.265M7.647 12h8.706m-8.706 4.353h8.706" />
          </g>
        </svg>
      );
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2em"
          height="2em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 0 0-7.071-7.071L9.878 7.05L8.464 5.636l1.414-1.414a7 7 0 0 1 9.9 9.9zm-2.829 2.828l-1.414 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 0 0 7.07 7.071l1.415-1.414zm-.707-10.607l1.415 1.415l-7.072 7.07l-1.414-1.414z"
          />
        </svg>
      );
  }
};
const UploadLinksModal = ({ modalDismiss }) => {
  const [loading, setLoading] = useState(false);
  const [showAddLink, setShowAddLink] = useState(false);
  const [InputLink, setInputLink] = useState("");
  const [Type, setType] = useState("");
  const [ProjectFiles, setProjectFiles] = useState([]);
  const [Links, setLinks] = useState([]);
  const Profile = useSelector((state) => state?.jobCandidateMatch?.profile);
  const [fetchingData, setFetchingData] = useState(false);
  const [warningMsg, setWarningMsg] = useState({});
  useEffect(() => {
    fetchExistingData();
  }, []);

  const fetchExistingData = async () => {
    try {
      setFetchingData(true);
      const response = await core.API(
        core.API_METHODS.GET,
        core.CANDIDATE_API.PORTFOLIO + "/" + Profile?.applicant_id,
        1,
        {},
      );

      if (response.code == 200 && Array.isArray(response?.data)) {
        // Separate links and files
        const data = response.data;
        const existingLinks = data
          .filter((item) => item.type === "link")
          .map((item) => ({
            link: item.link,
            type: item.title,
            id: item._id,
          }));

        const existingFiles = data
          .filter((item) => item.type === "document")
          .map((item) => ({
            name: item.title,
            id: item._id,
            url: item.link,
            alreadyPresent: true,
          }));

        setLinks(existingLinks);
        setProjectFiles(existingFiles);
      }
    } catch (error) {
      console.error("Error fetching portfolio data:", error);
    } finally {
      setFetchingData(false);
    }
  };

  const LinkValidation = () => {
    console.log("Inside");
    if (Type && InputLink) {
      const validation = validateLink(InputLink, Type);
      setWarningMsg(validation);
    }
  };
  const addLink = () => {
    if (InputLink && Type) {
      const validation = validateLink(InputLink, Type);

      if (!validation.isValid) {
        setWarningMsg(validation);
        return;
      }

      setLinks([...Links, { link: InputLink, type: Type }]);
      setInputLink("");
      setType("");
      setShowAddLink(false);
      setWarningMsg({});
    }
  };

  const deleteLink = (index) => {
    setLinks(Links.filter((_, idx) => idx !== index));
  };

  console.log(warningMsg);
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = files.map((file) => file);
    setProjectFiles([...ProjectFiles, ...newFiles]);
  };

  const deleteProjectFile = (index) => {
    setProjectFiles(ProjectFiles.filter((_, idx) => idx !== index));
  };

  useEffect(() => {
    LinkValidation();
  }, [InputLink, Type]);
  const SavePortfoliosDetails = async () => {
    try {
      setLoading(true);
      const newLinks = Links.filter((link) => !link.id);
      if (newLinks.length > 0) {
        newLinks.map(async (link, idx) => {
          const reqBody = {
            type: "link",
            title: link.type,
            link: link.link,
            candidate_details_id: Profile?.applicant_id,
          };
          const json = await core.API(
            core.API_METHODS.POST,
            core.CANDIDATE_API.PORTFOLIO,
            1,
            reqBody,
          );
          if (json) {
            console.log("Success ", json);
          }
        });
      }
      const newFiles = ProjectFiles.filter((file) => !file.alreadyPresent);
      if (newFiles.length > 0) {
        for (const file of newFiles) {
          const formData = new FormData();
          formData.append("files", file);
          formData.append("candidate_details_id", Profile?.applicant_id);
          formData.append("type", "document");

          const res = await fetch(core.CANDIDATE_API.PORTFOLIO, {
            method: "POST",
            body: formData,
          });

          const json = await res.json();
          if (json) {
            console.log("Success", json);
          }
        }
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
      modalDismiss();
    }
  };
  const modalElements = {
    heading: "Upload links and files",
    subheading:
      "Add profile links of various platforms and upload project files",
    modalDismiss: modalDismiss,
    onSave: SavePortfoliosDetails,
    defaultButtons: {
      primaryDisabled:
        loading || (Links.length == 0 && ProjectFiles.length == 0),
      primaryLabel: loading ? (
        <ScaleLoader height={14} color="#ffffff" loading={true} />
      ) : (
        "Save"
      ),
    },
  };

  return (
    <Modal {...modalElements} className={"sm:!max-w-[60%] !pb-5 h-fit"}>
      <div className="flex items-center gap-4">
        <label className="btn btn-primary flex items-center gap-2">
          <input
            type="file"
            multiple
            onChange={handleFileUpload}
            className="hidden"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.25em"
            height="1.25em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M10 16h4c.55 0 1-.45 1-1v-5h1.59c.89 0 1.34-1.08.71-1.71L12.71 3.7a.996.996 0 0 0-1.41 0L6.71 8.29c-.63.63-.19 1.71.7 1.71H9v5c0 .55.45 1 1 1m-4 2h12c.55 0 1 .45 1 1s-.45 1-1 1H6c-.55 0-1-.45-1-1s.45-1 1-1"
            />
          </svg>
          File
        </label>
        <button
          onClick={() => {
            setShowAddLink(true);
          }}
          className="btn btn-primary flex items-center gap-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M12 4c4.411 0 8 3.589 8 8s-3.589 8-8 8s-8-3.589-8-8s3.589-8 8-8m0-2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2m5 9h-4V7h-2v4H7v2h4v4h2v-4h4z"
            />
          </svg>
          Add link
        </button>
      </div>

      {showAddLink && (
        <div>
          <div className="flex items-end gap-2 my-8">
            <div className="w-5/12">
              <label className="w-full text-gray-800 text-sm not-italic font-normal">
                Link
              </label>
              <InputBox
                placeholder={"Enter or paste your link here"}
                type="text"
                name="title"
                value={InputLink}
                onChange={(event) => {
                  setInputLink(event.target.value);
                }}
                onBlur={LinkValidation}
                size="small"
                className="focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500 "
              />
            </div>
            <div className="w-5/12">
              <label className="w-full text-gray-800 text-sm not-italic font-normal">
                Type
              </label>
              <CustomDropdown
                selectedValue={Type}
                setSelectedValue={setType}
                allowCustom={false}
                LinkValidation={LinkValidation}
                placeholder="type"
                options={[
                  "Facebook",
                  "Instagram",
                  "Github",
                  "Coding platform",
                  "X",
                  "Portfolio",
                  "Others",
                ]}
              />
            </div>
            <button onClick={addLink} className="btn btn-primary h-[32px] px-4">
              Add
            </button>
          </div>
          {Object.keys(warningMsg).length !== 0 && (
            <div
              className={`flex items-center w-fit ${warningMsg.isValid ? "text-green-800 bg-green-100 border border-green-200 " : "text-yellow-800 bg-yellow-100 border border-yellow-200"} rounded-md px-2 py-1 my-1 gap-2`}
            >
              {!warningMsg.isValid ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 256 256"
                >
                  <g fill="currentColor">
                    <path
                      d="M215.46 216H40.54c-12.62 0-20.54-13.21-14.41-23.91l87.46-151.87c6.3-11 22.52-11 28.82 0l87.46 151.87c6.13 10.7-1.79 23.91-14.41 23.91"
                      opacity="0.2"
                    />
                    <path d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72m-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72M120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0m20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12" />
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18m-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
              <p className="ext-sm m-0">{warningMsg.message}</p>
            </div>
          )}
        </div>
      )}
      {Links.length > 0 && (
        <div className="my-4 transition-all duration-300 ease-in-out">
          <p className="text-base font-medium mb-2">Added links</p>
          <div className="flex flex-col gap-2 ">
            {Links.map((linkItem, idx) => {
              return (
                <div className="flex items-center gap-4 px-2 py-1 rounded-md shadow-md ">
                  <div className="text-gray-600 bg-gray-300 rounded-md p-2 h-auto cursor-pointer">
                    <a href={linkItem.link}>{GetLinkIcon(linkItem.type)}</a>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <div className="flex flex-col items-start gap-0.5">
                      <p className="text-sm  font-medium text-blue-700 m-0">
                        {linkItem.type}
                      </p>
                      <a className="text-base" href={linkItem.link}>
                        {linkItem.link}
                      </a>
                    </div>
                    <button
                      onClick={() => deleteLink(idx)}
                      className="text-red-800"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.6em"
                        height="1.6em"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          d="M7 3h2a1 1 0 0 0-2 0M6 3a2 2 0 1 1 4 0h4a.5.5 0 0 1 0 1h-.564l-1.205 8.838A2.5 2.5 0 0 1 9.754 15H6.246a2.5 2.5 0 0 1-2.477-2.162L2.564 4H2a.5.5 0 0 1 0-1zm1 3.5a.5.5 0 0 0-1 0v5a.5.5 0 0 0 1 0zM9.5 6a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0v-5a.5.5 0 0 1 .5-.5m-4.74 6.703A1.5 1.5 0 0 0 6.246 14h3.508a1.5 1.5 0 0 0 1.487-1.297L12.427 4H3.573z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {ProjectFiles.length > 0 && (
        <div className="my-4 transition-all duration-300 ease-in-out">
          <p className="text-base font-medium mb-2">Added Files</p>
          <div className="flex flex-col gap-2">
            {ProjectFiles.map((file, idx) => (
              <div
                key={idx}
                className="flex items-center gap-4 px-2 py-1 rounded-md shadow-md"
              >
                <div className="flex-shrink-0 text-gray-600 bg-gray-300 rounded-md p-2">
                  <a href={file.url}>{GetLinkIcon("Docs")}</a>
                </div>
                <div className="flex-1 flex items-center justify-between min-w-0">
                  <p className="text-base truncate text-blue-800 max-w-[calc(100%-2rem)]">
                    <a href={file.url}>{file.name}</a>
                  </p>
                  <button
                    onClick={() => deleteProjectFile(idx)}
                    className="flex-shrink-0 ml-2 text-red-800 hover:text-red-600 transition-colors"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.6em"
                      height="1.6em"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="currentColor"
                        d="M7 3h2a1 1 0 0 0-2 0M6 3a2 2 0 1 1 4 0h4a.5.5 0 0 1 0 1h-.564l-1.205 8.838A2.5 2.5 0 0 1 9.754 15H6.246a2.5 2.5 0 0 1-2.477-2.162L2.564 4H2a.5.5 0 0 1 0-1zm1 3.5a.5.5 0 0 0-1 0v5a.5.5 0 0 0 1 0zM9.5 6a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0v-5a.5.5 0 0 1 .5-.5m-4.74 6.703A1.5 1.5 0 0 0 6.246 14h3.508a1.5 1.5 0 0 0 1.487-1.297L12.427 4H3.573z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default UploadLinksModal;
