import React from "react";
import Modal from "../../components/modal/Modal";
import { useState } from "react";
import { useEffect } from "react";
import { ScaleLoader } from "react-spinners";

const core = require("../../lib/core");

function CreateRoleModal({ modalDismiss, Get_all_roles, loading, setLoading }) {
  const [name, setName] = useState();

  const POST_CREATE_ROLES = async () => {
    setLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.USER_API.POST_CREATE_ROLES,
        1,
        {
          name: name,
          resourceIds: Object.values(allResources).flatMap((section) =>
            section.filter((item) => item.checked).map((item) => item.id),
          ),
        },
      );
      if (json.data) {
        setName("");
        Get_all_roles();
      } else {
        console.log("error");
        Get_all_roles();
      }
    } catch (error) {
      modalDismiss();
    }
  };

  const onCancel = () => {
    modalDismiss();
  };

  const modalElements = {
    heading: "Create new role",
    subheading: "You can create a new role with customized permissions. ",
    modalDismiss: () => onCancel(),
    onSave: () => {
      POST_CREATE_ROLES();
    },
    defaultButtons: {
      primaryDisabled: !(name?.length > 0),
      primaryLabel: loading ? (
        <ScaleLoader height={14} color="#ffffff" loading={true} />
      ) : (
        "Save"
      ),
    },
  };

  const [allResources, setAllResources] = useState([]);

  const get_all_resources = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.USER_API.GET_ALL_RESOURCES,
      1,
    );
    if (json?.data) {
      setAllResources(json?.data);
    } else {
    }
  };

  const handleCheckboxChange = (section, id) => {
    setAllResources((prevData) => ({
      ...prevData,
      [section]: prevData[section].map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item,
      ),
    }));
  };

  useEffect(() => {
    get_all_resources();
  }, []);
  return (
    <Modal {...modalElements}>
      <div className="w-full flex flex-col">
        <h3
          className="w-full text-black text-sm font-normal"
          htmlFor="assessment-dropdown"
        >
          Role name
        </h3>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
        />
      </div>
      <h3
        className="w-full text-black text-sm font-normal"
        htmlFor="assessment-dropdown"
      >
        permissions
      </h3>
      {/* <div className="w-full grid grid-cols-2 gap-2">
                {allResources?.map(item => (
                    <div className='flex items-center' key={item?.id}>
                        <input
                            type='checkbox'
                            className=''
                            onChange={() => handleCheckboxChange(item?.id)}
                            checked={selectedIds.includes(item?.id)}
                        />
                        <label className='ml-[12px] text-black text-sm font-normal mb-0'>{item?.name}</label>
                    </div>
                ))}
            </div> */}

      <table className="min-w-full">
        <thead className="sticky top-0 z-50 bg-[#F2F4F8] text-[#252E4B]  text-[12px]">
          <tr>
            <th className="px-6 py-3 text-left  font-medium w-[50%]   tracking-wider">
              {"key"}
            </th>
            <th className="px-6 py-3 text-left  font-medium   tracking-wider">
              Read
            </th>
            <th className="px-6 py-3 text-left  font-medium   tracking-wider">
              Write
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Object?.entries(allResources)?.map(([key, values]) => (
            <tr>
              <td className="px-6 py-4 whitespace-no-wrap">{key}</td>
              {values?.filter((item) => item.action === "read")?.length > 0 ? (
                <>
                  {values
                    ?.filter((items) => items.action === "read")
                    ?.map((item) => (
                      <td
                        key={item.id}
                        className="px-6 py-4 whitespace-no-wrap"
                      >
                        <input
                          type="checkbox"
                          className="w-[18px] h-[18px] border-[1px] border-[#C9D5E2] rounded-[4px] bg-[#FFF] cursor-pointer"
                          checked={item?.checked}
                          onChange={() => handleCheckboxChange(key, item?.id)}
                        />
                      </td>
                    ))}
                </>
              ) : (
                <td className="px-6 py-4 whitespace-no-wrap">•</td>
              )}

              {values?.filter((item) => item.action !== "read")?.length > 0 ? (
                <>
                  {values
                    ?.filter((items) => items?.action !== "read")
                    ?.map((item) => (
                      <td
                        key={item.id}
                        className="px-6 py-4 whitespace-no-wrap"
                      >
                        <input
                          type="checkbox"
                          className="w-[18px] h-[18px] border-[1px] border-[#C9D5E2] rounded-[4px] bg-[#FFF] cursor-pointer"
                          checked={item.checked}
                          onChange={() => handleCheckboxChange(key, item?.id)}
                        />
                      </td>
                    ))}
                </>
              ) : (
                <td className="px-6 py-4 whitespace-no-wrap">•</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  );
}

export default CreateRoleModal;
